import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';



class AppState{


 public constructor( public databaseId:number){}
}

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

 appStateSubject = new Subject<AppState>();
public appState:AppState= new AppState(null);
public setDatabase(newVal:number)
{

  this.appState.databaseId = newVal;
  this.appStateSubject.next( this.appState);

}

public getLatestDatabase(setter:(number)=>void)
{
  this.appStateSubject.subscribe({
    next: (v:AppState) => setter(v.databaseId)
  })
  return  this.appState.databaseId;

}

  constructor() { }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColoursSchemeService {


  public getColours(): Object {

    return {
      "forMin": "#F08080",
      "forMax": "#B22222",
      "forAvg": "#6495ED",
      "forTarget": "#90EE90"
    }
  }

  public getColoursForStats(): Object{
    return {
      "forMin": "#F20202",
      "forMax": "#F20202",
      "forValue": "#6495ED",
      "forAvg": "#FFF700"
    }
  }
  public constructor() { }
}

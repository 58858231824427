import { Injectable } from '@angular/core';
import { CanActivate, Router, Route, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public authService: AuthenticationService,
    private router: Router) { }


  public canActivate(route: ActivatedRouteSnapshot): boolean {
    let authDone = this.authService.isUathenticated();

    if (authDone) return true;
    this.router.navigate(['login']);

  }
}






<div style="width:100%;float: left;">

  <div style="margin-top: 7%;text-align: center;">
    <img src="assets/images/DDE Logo.png" style="width:27%;height:10%;" class="image">
    <!-- <h1> Dynamic Data Exchange </h1> -->
    <div class="popup" id="popup">
      Dynamic Data Exchange
  </div>
  </div>
</div>




<div id="login_page" style="padding-top: 1%;align-content: center;display: flex;justify-content: center;height: 45%;">

  
<div style="display: initial !important;width: 49%;padding-top: 2%;">
    <form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)" style="align-content: center;justify-content:center;display:flex;" aria-labelledby="title">
    

<div style="width: 55%;">
  <div>
      <!-- <kendo-textbox-container style="width:100% ;font-size: 2vh;font-weight: 600;" floatingLabel="Email address:"> -->
        <kendo-label text="Email address:">
        
      </kendo-label>
      <input kendoTextBox type="email" style="font-size:smaller;width: 100%;" formControlName="email" />
      <!-- </kendo-textbox-container> -->
    </div>
      <div style="margin-top:10px">
      <kendo-label text="Password:">
        
      </kendo-label>
      <!-- <kendo-textbox-container style="width:100% ;font-size: 2vh;font-weight: 600;" text="Password:"> -->
        <input kendoTextBox type="password" style="font-size:smaller;width:100%" formControlName="password" />
      <!-- </kendo-textbox-container> -->
    </div>


      <button kendoButton type="submit" style="width: 100%;background-color: #0185d1 !important;font-size: 2vh; font-weight: 600; color: white;background-image: none;"> LOG IN</button>
    </div>

    </form>
</div>


  <!-- <div id="video_container" style="height:100%;">
    <video loop controls>
      <source src="assets/What is ARPRO 720 HD.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div> -->
</div>

<div style="width:100%;text-align:center;bottom: 2%;position: fixed;">
  <img src="assets/images/logo-jsp.png" style="height:5%;width:6%">
</div>
  

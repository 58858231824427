import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {

  }

  intercept(request: HttpRequest<any>, newRequest: HttpHandler): Observable<HttpEvent<any>> {
    const authtoken = this.authenticationService.getToken();
    if (authtoken !== '') {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authtoken}`
        }
      });
    }

    return newRequest.handle(request);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { environment } from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  apiURL: string = environment.API_URL + "/auth";

  someVar: string;


databasesList;
  constructor(private httpClient: HttpClient,
    private router: Router) { }


  public getToken() {
    return localStorage.getItem('token');
  }

  public getDatabasesList() {
    if (localStorage.getItem('token') == null)
      return null;

    if (this.databasesList == undefined)
      this.databasesList = JSON.parse(this.getDecodedToken().availableDbs);
    return this.databasesList;
  }

  public getUserId():number
  {
    return  this.getDecodedToken().sub;
  }

  private getDecodedToken() {
    return jwt_decode(this.getToken());
  }


  private setToken(token: string) {
    localStorage.setItem('token', token);
  }


  public Login(user: any) {

    localStorage.removeItem('token');
    this.databasesList=undefined;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': ' Content-Type, Authorization'

      })
    };

    this.httpClient.post(`${this.apiURL}/login`, user, httpOptions).subscribe(
      (data: any) => {
        if (data.success) {
          this.setToken(data.auth_token);

          this.router.navigate(['./pages']);
        } else {

        }
      },
      (error: any) => { }
    );
  }

  public extendUserSession(autoRefresh:Boolean){
    //localStorage.removeItem('token');
    //this.databasesList=undefined;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': ' Content-Type, Authorization'

      })
    };
    var token= localStorage.getItem('token');
    var userId=this.getUserId();

    let obj = { "token": token,"extend":autoRefresh,"userId":userId };
    this.httpClient.post(`${this.apiURL}/GenerateExtendedJwtToken`, obj).subscribe(
      (data: any) => {
        if (data.success) {
          
          localStorage.removeItem('token');
          this.setToken(data.auth_token);
          //this.router.navigate(['./pages']);
        } else {

        }
      },
      (error: any) => { }
    );
  }
  public UpdateLogoutLog(token: string){
    var userId=this.getUserId();
    let obj = { "token": token,"extend":false,"userId":userId };
    this.httpClient.post(`${this.apiURL}/updateuserlog`, obj).subscribe(
      (data: any) => {
        if(data === 1){
          localStorage.removeItem('token');
        }
      });
  }


  public logout() {

    localStorage.removeItem("CurrentConfig");
    let token = this.getToken();
    this.UpdateLogoutLog(token);
  }

  public isUathenticated() {

    //TODO implement token expiration logic
    let token = this.getToken();
    if (token == null)
      return false;
    return true;

  }

  public getLoggedInUserData() {
    return localStorage.getItem('LoggedInUser');
  }

}

import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DrawerItem, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { AuthenticationService } from '../../services/authentication.service'
import { Router, ActivatedRoute, ParamMap, NavigationStart, NavigationEnd, NavigationError, Event } from '@angular/router';
import { map } from 'rxjs/operators';
import { AppStateService } from 'src/app/services/app-state.service';
import { Configuration, ConfigurationAndCustomerInfo } from '../../../models/Configuration';

import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { connect } from 'http2';
import { DashboardService } from 'src/app/services/dashboard.service';



@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit, AfterViewInit {


  databaseItems;
  selectedDb;
  DBselectIsPresent;
  customerLogo;
  public selected;
  public tooltip;
  isAutoRefresh = true;

  speedValue = "Checking...";

  currentConfiguration: ConfigurationAndCustomerInfo = null;
  processDataLoadingService: any;


  @Input() set configurationChange(value: ConfigurationAndCustomerInfo) {

    this.currentConfiguration = value;
    // this.reloadDataForPlot(this.configuration);

  }

  public selectedDatabaseChange(val: any) {
    let correspondingDatabase = this.databaseItems.find(dbInfo => dbInfo.databaseId == val.databaseId);

    this.customerLogo = correspondingDatabase.logoPath;
    document.getElementById("customerTextBlock").innerHTML = correspondingDatabase.text
    this.selectedDb = val;

    this.appStateService.setDatabase(this.selectedDb.databaseId);

  }

  preselectedDatabaseId;
  reportConfig;

  constructor(private authenticationService: AuthenticationService, public dashboard: DashboardService,
    private router: Router, private route: ActivatedRoute, private appStateService: AppStateService) {

    var routingExtras = this.router.getCurrentNavigation().extras;
    if (routingExtras.state) {
      if (routingExtras.state.data.tabType)
        this.selected = routingExtras.state.data.tabType
      if (routingExtras.state.data.reportConfig) {
        this.reportConfig = routingExtras.state.data.reportConfig
        this.preselectedDatabaseId = this.reportConfig.currentConfiguration.databaseId
      }

      this.navigateToAnotherTab()

    }
    else this.selected = 'Stats';




    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        var currentRoute = event.url;

        var selectedText = event.url.toString().split('/')[2]

        this.selected = selectedText;
        this.setSlection();
      }

    });

  }
  docElement: HTMLElement;
  public goToDisplay() {


    let local = localStorage.getItem("conf");




    let currentconf = JSON.parse(localStorage.getItem("currentConfiguration"));

    let dataItem = JSON.parse(localStorage.getItem("dataItem"));
    
    // this.dashboardService.createSavedObject(this.selectedSensors, this.selectedDays, this.selectedCycles, this.sensorsToPlot, this.cyclesToPlot, this.trimStart, this.firstclick, 
    //   this.trimEnd, this.secondclick, this.stats, this.valueAxes, this.qualityTrim);
    this.dashboard.createSavedObject(null, null, null, null, dataItem, null, null,
      null, null, currentconf, null, null);

    this.router.navigate(['/pages/Display View'], {
      state: {
        skipLocationChange: false, data: {
          tabType: 'Curve'
        }
      }
    });
    this.docElement.requestFullscreen();
  }

  ngOnInit(): void {
    // this.goToDisplay();

    this.docElement = document.documentElement;


    if (!this.selected)
      this.selected = 'Stats';
    this.databaseItems = this.authenticationService.getDatabasesList();

    let jspIsPresent = false;


    if (this.preselectedDatabaseId) {
      this.selectedDb = this.databaseItems.find(el => el.databaseId == this.preselectedDatabaseId) || this.databaseItems[0];
      this.customerLogo = this.selectedDb.logoPath;
      document.getElementById("customerTextBlock").innerHTML = this.selectedDb.text
    }
    else {

      for (let index = 0; index < this.databaseItems.length; index++) {

        if (this.databaseItems[index].customerName.toLowerCase().includes("jsp")) {
          jspIsPresent = true;
          this.selectedDb = (this.databaseItems[index]);
          this.customerLogo = this.databaseItems[index].logoPath;
          document.getElementById("customerTextBlock").innerHTML = this.databaseItems[index].text;
          break;
        }

      }

      if (!jspIsPresent) {
        this.selectedDb = (this.databaseItems[0]);
        this.customerLogo = this.databaseItems[0].logoPath;
        document.getElementById("customerTextBlock").innerHTML = this.databaseItems[0].text;;

      }
    }
    this.preselectedDatabaseId = null
    this.DBselectIsPresent = this.databaseItems.length > 1;

    this.items.forEach(el => {

      if (el.text == this.selected)
        el.selected = true
      else
        el.selected = false
    })


    this.setConnectivityIcon()

    this.appStateService.setDatabase(this.selectedDb.databaseId);

    let timerId = setTimeout(() => { if (window.location.href.includes("Stats")) { window.location.reload() } }, 300000);
    if (localStorage.getItem("AutoRefreshStatus") != null) {
      this.items[6].icon = "k-i-non-recurrence";
    }
    else {
      clearTimeout(timerId);
    }


    //alert("came here")

  }

  public items: Array<DrawerItem> = [
    { text: 'Stats', icon: 'k-i-align-bottom-elements', selected: true },
    { text: 'Curve', icon: 'k-i-crop' },
    { text: 'Configuration', icon: 'k-i-gear' },
    { text: 'Display View', icon: 'k-i-window' },
      { text: 'Reporting', icon: 'k-i-js' },
      // { text: 'QualityControl', icon: 'k-i-parameters' },
   //   {text:'Process-Coridor',icon:'k-i-graph'},
      {text:'OEE',icon:'k-i-layout'},
    { text: 'Auto Refresh', icon: 'k-i-non-recurrence', cssClass: 'bottom-refresh-item' },
    { text: this.speedValue, icon: 'k-i-rss', cssClass: 'bottom-internet' },
    //{ text: 'ReportingMockup', icon: 'k-i-js' },
    { icon: 'k-i-logout', cssClass: 'bottom-menu-item', text: 'Logout' }
    /*,
    { separator: true,  }
    */
  ];

  public onSelect(ev: DrawerSelectEvent): void {
    //console.log(ev)
    if (ev.item.text == 'Logout') {
      this.authenticationService.logout();
      this.router.navigate(['login']);
      return;
    }
    else if (ev.item.text.includes("Refresh")) {


      let timerId = setTimeout(() => { if (window.location.href.includes("Stats")) { window.location.reload(); } }, 300000);
      //alert(timerId); // timer identifier

      if (ev.item.icon == 'k-i-non-recurrence') {
        ev.item.icon = "k-i-reload";
        this.isAutoRefresh = false;
        localStorage.setItem("AutoRefreshStatus", "1");

      }
      else {
        ev.item.icon = "k-i-non-recurrence";
        this.isAutoRefresh = true;
        clearTimeout(timerId);

        localStorage.removeItem("AutoRefreshStatus");

      }
    }
    this.selected = ev.item.text != "Auto Refresh" && ev.item.icon != "k-i-rss" ? ev.item.text : this.selected;
    //console.log(this.selected,"selected value")
    //this.setSlection();
    setTimeout(() => this.setSlection(), 5);

    if (ev.item.text != "Auto Refresh" && ev.item.icon != "k-i-rss") {
      this.navigateToAnotherTab();
      this.createOnline$().subscribe();
    }
    else {
      this.createOnline$().subscribe();
      this.maintainSessionandSelection();
    }

  }


  setSlection() {
    // clearTimeout();
    this.items.forEach(el => {
      // console.log("el text", el.text)
      //  console.log("selected text", this.selected)
      // var selectedClass=el.cssClass.toString();

      if (el.text != "Auto Refresh" && el.text != "Logout" && el.icon != "k-i-rss") {

        if (el.text == this.selected && el.text != "Auto Refresh" && el.text != "Logout" && el.icon != "k-i-rss") {
          el.selected = true;

          //el.cssClass=""
          el.cssClass += " k-state-selected"
        }
        else {
          el.selected = false;
          if (el.text == "Auto Refresh") {
            el.cssClass = "bottom-refresh-item"
          }
          else if (el.text == "Logout") {
            el.cssClass = "bottom-menu-item"
          }
          else if (el.icon == "k-i-rss") {
            el.cssClass = "bottom-internet"
          }
          else {
            el.cssClass = "k-drawer-item";
          }
        }


        // el.cssClass=selectedClass.replace("K-state-selected","");

      }
    })
  }

  maintainSessionandSelection() {



    this.authenticationService.extendUserSession(!this.isAutoRefresh);
  }

  navigateToAnotherTab() {

    if (this.selected == 'Reporting') {
      this.router.navigate(['/pages/' + this.selected], { state: { skipLocationChange: false, data: { reportConfig: this.reportConfig } } });

    }
    else if (this.selected == 'Display View') {
      this.goToDisplay();
    }
    else
    {
      console.log(this.selected)
      this.router.navigate(['/pages/' + this.selected]);
    }

  }


  ngAfterViewInit() {
    this.navigateToAnotherTab();
  }

  public showTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if (element.nodeName === 'LI') {
      this.tooltip = element.getAttribute("aria-label");
    }
    else if (element.nodeName === 'SPAN') {
      this.tooltip = element.parentElement.getAttribute("aria-label");
    }
    else {
      this.tooltip = "";
    }
  }






  setConnectivityIcon() {

    if (this.getBrowserName() == "firefox") {


      this.items.forEach(el => {
        if (el.icon == "k-i-rss") {
          el.cssClass = "bottom-internet-display-none"
        }


      })

    }
    else {


      let networkStatusTimer = setInterval(() => {
        this.createOnline$().subscribe(conInfo => {
          
          var dLinkSpeed = conInfo != null ? conInfo["connection"].downlink : null;




          //this.speedValue=conInfo["connection"].downlink+"Mbps"
          this.items.forEach(el => {
            if (el.icon == "k-i-rss") {

              if (dLinkSpeed == null) {
                el.cssClass = "bottom-internet-display-none"
              }
              else if (dLinkSpeed > 7) {
                el.cssClass = "bottom-internet-item"
                this.speedValue = "Good Connection"

              } else if (dLinkSpeed > 5) {
                el.cssClass = "bottom-internet-item-orange"
                this.speedValue = "Average Connection"
              }
              else {
                el.cssClass = "bottom-internet-item-yellow"
                this.speedValue = "Poor Connection"
              }

              el.text = this.speedValue;
            }



          });





        });

      }, 30000);


    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.sendTypingEvent(event);
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEventsecond(event: KeyboardEvent) {
    this.sendTypingEvent(event);
  }
  keymap = {}; 
  sendTypingEvent(ev) {
 
    this.keymap[ev.keyCode] = ev.type == 'keydown';
  

    if ((   this.keymap[13] &&    this.keymap[17] )&&  (this.selected != 'Reporting')) {
      this.goToDisplay();
      this.keymap = {}; 
    }
    


  }
  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }


  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<any>) => {
        sub.next(navigator);
        sub.complete();
      }));
  }

}

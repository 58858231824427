<div id="Curve" class="menu-page " *ngIf="selectedItem === 'Curve'">
 </div>
<div id="Configuration" class="menu-page " *ngIf="selectedItem === 'Configuration'">

</div>
<div id="Reporting" class="menu-page " *ngIf="selectedItem === 'Reporting'">
</div>

<div id="Process-Coridor" class="menu-page " *ngIf="selectedItem === 'Process-Coridor'">
</div>







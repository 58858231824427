import { Injectable } from '@angular/core';
import { HttpClientErrorHandlingService } from '../services/http-err-handling.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QualityMeasurement } from 'src/models/Configuration';
import { SharedServicesModule } from '../modules/shared-services/shared-services.module';

@Injectable({
  providedIn: SharedServicesModule
})


export class ProcessDataLoadingService {




  public setCycleStatsPerTimeInterval(dbId: number, machineId: number,
    end: Date, start: Date,
    projectId: number = null, toolId: number = null, programId: number = null,
    setter: (arg1) => void) {


    let q = `${environment.API_URL}/processdata/CycleStatsPerPeriod?databaseId=${dbId}&machineId=${machineId}&end=${end.toUTCString()}&start=${start.toUTCString()}`

    if (projectId != null)
      q = q.concat(`&projectId=${projectId}`)

    if (programId != null)
      q = q.concat(`&programId=${programId}`)
    if (toolId != null)
      q = q.concat(`&toolId=${toolId}`)


    this.httpClient.get(q).subscribe(
      (data: any) => {

        setter(data.map(item => {

          item.time = new Date(item.time);
          return item;
        }));

      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      }

    );

    //this.checkMissingConfiguration(dbId,machineId,end,start,projectId,toolId,programId,setter);
  }

  public setCycleStatsPerTimeIntervalForDisplayView(setter: (arg1) => void,databaseId:number,machineId:number) {


    let q = `${environment.API_URL}/processdata/CycleStatsPerPeriodForDisplayView/${databaseId}/${machineId}`;

    this.httpClient.get(q).subscribe(
      (data: any) => {

        setter(data.map(item => {

          item.time = new Date(item.time);
          return item;
        }));

      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      }

    );

    //this.checkMissingConfiguration(dbId,machineId,end,start,projectId,toolId,programId,setter);
  }





  public checkMissingConfiguration(dbId: number, machineId: number,
    end: Date, start: Date,
    projectId: number = null, toolId: number = null, programId: number = null,
    setter: (arg1) => void) {


    let q = `${environment.API_URL}/processdata/checkConfigurationSettings?databaseId=${dbId}&machineId=${machineId}&end=${end.toUTCString()}&start=${start.toUTCString()}`

    if (projectId != null)
      q = q.concat(`&projectId=${projectId}`)

    if (programId != null)
      q = q.concat(`&programId=${programId}`)
    if (toolId != null)
      q = q.concat(`&toolId=${toolId}`)


    this.httpClient.get(q).subscribe(
      (data: any) => {
        // return data;
        //  console.log(data);
        // setter(data.map(item => {

        //   item.time = new Date(item.time);
        //   return item;
        // }));
        setter(data);


      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      }

    );
  }

  public setMachineDownTimePerInterval(dbId: number, machineId: number, end: Date, start: Date,
    setter: (arg1) => void) {

    let q = `${environment.API_URL}/processdata/MachineDownTimePerPeriod?databaseId=${dbId}&machineId=${machineId}&end=${end.toUTCString()}&start=${start.toUTCString()}`


    this.httpClient.get(q).subscribe(
      (data: any) => {

        setter(data.map(item => {

          // item.time = new Date(item.time);
          return item;
        }));

      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      }

    );
  }


  public setStatsPerCycle(readingIdxs: number[], dbId: number, cycleId: number[],
    setter: (destinationDSource) => void) {
    if (readingIdxs) {
      let q = `${environment.API_URL}/processdata/StatsPerCycles?databaseId=${dbId}`
      cycleId.forEach((el, idx) => q = q.concat(`&cycleIdxs[${idx}]=${el}`));
      readingIdxs.forEach((el, idx) => q = q.concat(`&readingIdxs[${idx}]=${el}`));


      this.httpClient.get(q).subscribe(
        (data: any) => {

          setter(data)
        }, (error: any) => {
          this.httpErrHandlingService.process(error);

        })
    }

  }



  public setProcessDataPercycle(readingIdxs: number[], dbId: number, cycleId: number[],
    setter: (destinationDSource) => void) {
    if (readingIdxs) {
      let q = `${environment.API_URL}/processdata/CurveDataPerCycles?databaseId=${dbId}`
      cycleId.forEach((el, idx) => q = q.concat(`&cycleIdxs[${idx}]=${el}`));
      readingIdxs.forEach((el, idx) => q = q.concat(`&readingIdxs[${idx}]=${el}`));


      this.httpClient.get(q).subscribe(
        (data: any) => {

          setter(data)
        }, (error: any) => {
          this.httpErrHandlingService.process(error);
        })
    }

  }


  public setProcessDataPerTimeInterval(readingIdx: number, dbId: number, machineId: number,
    end: Date, start: Date, trimStart: number, trimEnd: number,
    projectId: number = null, partId: number = null, programId: number = null,
    setter: (arg1, arg2, arg3, startTimeArg, endTimeArg) => void) {


    let q = `${environment.API_URL}/processdata/sensordataaggregation?databaseId=${dbId}&readingIdx=${readingIdx}&machineId=${machineId}&end=${end.toUTCString()}&start=${start.toUTCString()}&trimStart=${trimStart}&trimEnd=${trimEnd}`

    if (projectId != null)
      q = q.concat(`&projectId=${projectId}`)

    if (programId != null)
      q = q.concat(`&programId=${programId}`)
    if (partId != null)
      q = q.concat(`&partId=${partId}`)


    this.httpClient.get(q).subscribe(
      (data: any) => {

        let minY = Number.POSITIVE_INFINITY;
        let maxY = Number.NEGATIVE_INFINITY;
        let startTime = null;
        let endTime = null;
        if (data.length != 0) {
          startTime = new Date(data[0].productionTime);
          endTime = new Date(data[data.length - 1].productionTime);
        }

        setter(data.map(item => {

          minY = Math.min(minY, item.min);
          maxY = Math.max(maxY, item.max);
          item.time = new Date(item.productionTime);
          return item;
        }), minY, maxY, startTime, endTime);

      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      }

    );


  }

  constructor(private httpClient: HttpClient, private httpErrHandlingService: HttpClientErrorHandlingService) { }
}

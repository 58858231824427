<div class="orientation" id="orientation" style="height: 100%; width:100%;">


    <div class='header'>

        <div class='leftheader'>
            <span>
                <img src="assets/images/DDE_Logo_WOArpro.png" style="margin-left:5%; width:10%;height:50%;">
            </span>
        </div>


        <div class='rightheader'>
            <span>
                <img src="assets/images/logo-jsp.png" style="margin-left:85%;height:50%;width:10%;">
            </span>
        </div>




    </div>

    <div style="height: 95%; width:100%;">


        <div style="height: 30%; ">

            <div class="row buttonsList">

                <div class='leftgroup'>
                    <button class="custom-size" id="id1" kendoButton [look]="'outline'" [disabled]="true"
                        style="color: #eb008b!important;margin-left: 5% ;"> {{dateToSHow| date:'dd/MM/yyyy'}} - Cycle
                        Number
                        -
                        {{cyclenumber}}</button>
                    <button kendoButton (click)=" onButtonClickPrev()" [disabled]="Prevdisabled" class="custom-size"
                        id="id2" style="margin-left: 0.3% ;">Prev</button>
                    <button kendoButton (click)="onButtonClicknext()" [disabled]="disabled" class="custom-size" id="id3"
                        style="margin-left: 0.3% ;">Next</button>
                </div>
                <div class='rightgroup'>
                    <button kendoButton (click)="onTabClick()" class="custom-size" id="id4"
                        style="margin-left: 80% ;">TAB</button>
                </div>
            </div>

            <div class="row qualityList">
                <div class="col-md-12 no_border_class" id="id5">
                    <div class="outer">
                        <div class="inner">
                            <span>
                                <button class="custom-size-for-qualty  no_border_button" kendoButton [look]="'outline'"
                                    *ngFor="let button of topbuttons; index as i; first as isFirst"
                                    (click)="changeState($event)"
                                    [selected]="button.isSelected">{{button.text}}</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div style="height: 60%; ">
                <kendo-chart #chartto [transitions]="false" (zoomEnd)="onZoomEnd($event)" (drag)="onDrag($event)"
                    (zoom)="onZoom($event)" (dragEnd)="onDragEnd($event)" [pannable]="true" [zoomable]="true"
                    (render)="highlightPoint($event)" (seriesClick)="goToCurve($event)"
                    (seriesHover)="showPoint($event)" style="height: 100%;">
                    <kendo-chart-x-axis>
                        <kendo-chart-x-axis-item [baseUnit]="'hours'" [type]="'date'" [majorUnit]=" step"
                            [name]="'xAxis'" [labels]="valueAxisLabelsYCycle" [max]="maxX" [min]="minX">
                            <kendo-chart-x-axis-item-crosshair>
                                <kendo-chart-x-axis-item-crosshair-tooltip format="dd/MM/yy HH:mm">
                                </kendo-chart-x-axis-item-crosshair-tooltip>
                            </kendo-chart-x-axis-item-crosshair>
                        </kendo-chart-x-axis-item>
                    </kendo-chart-x-axis>
                    <kendo-chart-y-axis>
                        <kendo-chart-y-axis-item [type]="'numeric'" [majorUnit]="stepY"
                            [title]="{ text: unitsText,font:'bold 100% Arial, sans-serif'}"
                            [axisCrossingValue]="axisCrossingValue" [name]="'yAxis'" [max]="maxY" [min]="minY"
                            [labels]="valueAxisLabelsXCycle">
                            <kendo-chart-y-axis-item-crosshair>
                                <kendo-chart-y-axis-item-crosshair-tooltip format='n1'>
                                </kendo-chart-y-axis-item-crosshair-tooltip>
                            </kendo-chart-y-axis-item-crosshair>
                        </kendo-chart-y-axis-item>
                    </kendo-chart-y-axis>
                    <kendo-chart-series>
                        <kendo-chart-series-item
                            [markers]="{ visible: false, size:0, border:{width:0}, background:colours.forTarget}"
                            type="scatterLine" [data]="seriesData" xField="time" yField="target" zIndex="10"
                            [color]="colours.forTarget" [missingValues]="'gap'">
                        </kendo-chart-series-item>
                        <kendo-chart-series-item type="scatter" [missingValues]="'gap'" zIndex="11" [data]="seriesData"
                            xField="time" yField="actualValue" [color]="colours.forAvg" categoryField="actualValue"
                            [highlight]="highlightSettings">
                        </kendo-chart-series-item>
                        <kendo-chart-series-item [missingValues]="'gap'"
                            [markers]="{ visible: false , size:0 , border:{width:0}, background:colours.forMin}"
                            type="scatterLine" [data]="seriesData" xField="time" yField="min" [color]="colours.forMin">
                        </kendo-chart-series-item>
                        <kendo-chart-series-item [missingValues]="'gap'"
                            [markers]="{ visible: false, size:0, border:{width:0} , background:colours.forMax}"
                            type="scatterLine" [data]="seriesData" xField="time" yField="max" [color]="colours.forMax">
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>
            </div>

        </div>

        <div style="height: 68%">
            <div *ngIf="loading == true" class="spinner-wrapper">
                <div class="spinner-border" role="status" style="color: #eb008b; width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="loading == false" style="height: 93%;">

                <kendo-chart renderAs="canvas" [pannable]="isZoomed"
                    [zoomable]="{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }" [seriesColors]="seriesColor"
                    (render)="plotExistingTrim($event)" [transitions]="false" style="height: 100%;">
                    <kendo-chart-axis-defaults [minorGridLines]="{visible:false}" step="1">
                    </kendo-chart-axis-defaults>
                    <kendo-chart-value-axis *ngIf="valueAxes.length > 0">
                        <kendo-chart-value-axis-item [axisCrossingValue]="axisCrossingValue"
                            *ngFor="let item of valueAxes" [name]="item.name"
                            [title]="{ text: item.name,font:'bold 100%  Arial, sans-serif' }"
                            [labels]="valueAxisLabelsXCurve" step="1">
                        </kendo-chart-value-axis-item>
                    </kendo-chart-value-axis>
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item [min]="axisMin" [max]="axisMax" [labels]="valueAxisLabelsYCurve"
                            [name]="'category'"
                            [title]="{ text: 'sec', position: 'right',font:'bold 100%  Arial, sans-serif' }">
                        </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                    <kendo-chart-series>
                        <kendo-chart-series-item *ngFor="let item of plot" [data]="item.data" field="sensorValue"
                            categoryField="measurementTime" type="line" [markers]="{ visible: false }"
                            [axis]="item.axis" [name]="item.name" [labels]="valueAxisLabelsYCurve"
                            [missingValues]="'gap'" step="1">
                            {{item|json}}
                            <!-- <kendo-chart-series-item-tooltip background="white" [visible]="'true'">
                                <ng-template let-value="value" let-category="category">
                                    <div>
                                        {{item.data[0].cview.productionTime}} - {{item.data[0].cview.cview}}
                                    </div>
                                    <div>
                                        <strong>Value:</strong> {{value | number}}
                                    </div>
                                    <div style="text-align: center;" *ngIf="enableSwitch">
                          <button kendoButton  (click)="shiftCurve(item.data)">Shift Curve</button><br>
                        </div> 
                                </ng-template>
                            </kendo-chart-series-item-tooltip> -->
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                </kendo-chart>

                <div *ngIf="!popupLegend">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" style="text-align: center;background-color: #eb008b;color:white">
                            <a> Sensors</a>
                        </li>
                        <li class="list-group-item" [ngStyle]="{'color': legendItem.color ? legendItem.color : 'gray' }"
                            *ngFor="let legendItem of sensorsToPlot">
                            <span class="k-icon k-i-minus"></span> {{legendItem.sensorName}}
                        </li>
                    </ul>
                </div>
                <kendo-window class="k-window" *ngIf="popupLegend" [width]="windowwidth" [height]="windowHeight"
                    [(top)]="windowTop" [(left)]="windowLeft">
                    <kendo-window-titlebar>
                        <div class='k-window-title'>Sensors </div>
                        <button kendoButton (click)="pinLegend()" [icon]="'pin'" [look]="'bare'"></button>
                    </kendo-window-titlebar>
                    <ul style="text-align: center;" class="list-group">
                        <li class="list-group-item" [ngStyle]="{'color': legendItem.color ? legendItem.color : 'gray' }"
                            *ngFor="let legendItem of sensorsToPlot">
                            <span class="k-icon k-i-minus"></span> {{legendItem.sensorName}}
                        </li>
                    </ul>
                </kendo-window>
            </div>
            <div class='rightgroupList'>
                <button class="custom-size setBackground" id="id6" kendoButton [look]="'outline'" [disabled]="true"
                    [selected]="true">Cycle time =
                    {{cycleTimeValue}} s</button>
                <button class="custom-size setBackground" id="id7" kendoButton [look]="'outline'" [selected]="true"
                    [disabled]="true">Air
                    =
                    {{airConsumption}} m³</button>
                <button class="custom-size setBackground" id="id8" kendoButton [look]="'outline'" [disabled]="true"
                    [selected]="true">Electricity =
                    {{electricityConsumption}} KWh</button>
                <button class="custom-size setBackground" id="id9" kendoButton [look]="'outline'" [disabled]="true"
                    [selected]="true">Steam =
                    {{steamConsumption}} Kg</button>
                <button class="custom-size setBackground" id="id10" kendoButton [look]="'outline'" [disabled]="true"
                    [selected]="true">CO<sub><sub>2</sub></sub>/Cycle =
                    {{cO2}} Kg</button>
            </div>
        </div>

    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/auth/login/login.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { AuthenticationService } from './services/authentication.service';
import { NoPageComponent } from './components/no-page/no-page.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MenuPagesComponent } from './components/main-menu/menu-pages.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import { UploadInterceptor } from './services/upload-interceptor';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ChartModule } from '@progress/kendo-angular-charts';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { LabelModule } from '@progress/kendo-angular-label';
import 'hammerjs';
import { MockupComponent } from './components/pages/mockup/mockup.component';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DisplayViewComponent } from './components/display-view/display-view.component';
import { DataRangeManagementService } from './services/data-range-management.service';
import { ProcessDataLoadingService } from './services/process-data-loading.service';
import { QualityControlViewComponent } from './components/pages/quality-control-configuration/quality-control-view/quality-control-view.component';
import { QualityControlChartsComponent } from './components/pages/quality-control-configuration/quality-control-charts/quality-control-charts.component';
import { DatePipe } from '@progress/kendo-angular-intl';








@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainMenuComponent,
    NoPageComponent,
    MenuPagesComponent,
    MockupComponent,
    DisplayViewComponent
    
    

  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    InputsModule,
    BrowserAnimationsModule,
    ButtonsModule,
    ReactiveFormsModule,
    FormsModule,
    LayoutModule,
    DropDownsModule,
    DateInputsModule,
    DialogModule,
    LabelModule,
    WindowModule,
    NotificationModule,
    ChartModule
  ],
  providers: [AuthenticationService, DataRangeManagementService, ProcessDataLoadingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    },

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { StartsWithFilterOperatorComponent } from '@progress/kendo-angular-grid';
import { anyChanged } from '@progress/kendo-angular-common';


@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  apiURL: string = environment.API_URL + "/dashboard";
  object: any;

  constructor(private httpClient: HttpClient) { }

  // public loadSensorsAndCycles(db: number, machineId: number, partNumberId: number, programId: number, first: string, second: string) {

  //   return this.httpClient.get(`${this.apiURL}/loadSensorsAndCycles/${db}/${machineId}/${partNumberId}/${programId}/${first}/${second}`);
  // }

  public loadSensorsAndCycles(db: number, machineId: number, partNumberId: number, programId: number, first: string, second: string) {
    if (partNumberId == null) {
      partNumberId = 0;
    }
    if (programId == null) {
      programId = 0;
    }
    return this.httpClient.get(`${this.apiURL}/loadSensorsAndCycles/${db}/${machineId}/${partNumberId}/${programId}/${first}/${second}`);
  }



  public loadSensorsAndCyclesForDisplay(databaseId:number,machineId:number) {

    return this.httpClient.get(`${this.apiURL}/loadSensorsAndCyclesForDisplayView/${databaseId}/${machineId}`);
  }
  public loadPreviousSensorsAndCyclesForDisplay(cycleId: number,databaseId:number,machineId:number) {

    return this.httpClient.get(`${this.apiURL}/loadPreviousSensorsAndCyclesForDisplayView/${cycleId}/${databaseId}/${machineId}`);
  }
  public loadSelectedSensorsAndCyclesForDisplay(cycleId: number,databaseId:number,machineId:number) {

    return this.httpClient.get(`${this.apiURL}/loadSelectedSensorsAndCyclesForDisplayView/${cycleId}/${databaseId}/${machineId}`);
  }
  public loadNextSensorsAndCyclesForDisplay(cycleId: number,databaseId:number,machineId:number) {

    return this.httpClient.get(`${this.apiURL}/loadNextSensorsAndCyclesForDisplayView/${cycleId}/${databaseId}/${machineId}`);
  }


  public getCurve(db: number, day: string, startTime: string, endTime: string, cycleId: number, sensor: string) {



    return this.httpClient.get<any[]>(`${this.apiURL}/getCurve/${db}/${day}/${startTime}/${endTime}/${cycleId}/${sensor}`);
  }


  public getCurveofcycles(db: number, day: string, startTime: string, endTime: string, cycleId: number, sensor: string, cycles: string) {

    var cyclesTopass;
    

    //var csplit=cycles.split(',');
    try{

      if(cycles.split(',').length>1){
        cyclesTopass=cycles;
      }
      else{
        cyclesTopass=cycles[0];
      }

    }
    catch(exception){
      if(cycles!=null){
      if(cycles.length==1){
        cyclesTopass=cycles[0];
      }
      else{
        cyclesTopass=cycles.toString();
      }
    }else {
      cyclesTopass=null;
    }
      
      //cyclesTopass=cycles;
    }


    let obj={"db":db,"day":day,"startTime":startTime,"endTime":endTime,"cycleId":cycleId,"sensor":sensor,"cycles":cyclesTopass}
    console.log(obj,"The curve data")

    //return this.httpClient.get<any[]>(`${this.apiURL}/getCurve/${db}/${day}/${startTime}/${endTime}/${cycleId}/${sensor}/${cycles}`);

    try{
    return this.httpClient.post<any[]>(`${this.apiURL}/getCurves`,obj);
    }
    catch(exception ){
      console.log(exception)
    }
  }

  public createSavedObject(sensors, days, cycles, sensorsToPlot, cyclesToPlot, trimStart, firstclick, trimEnd, secondclick, stats, valueAxis, qualityTrim) {
    this.object = {
      sensors: sensors, days: days, cycles: cycles, sensorsToPlot: sensorsToPlot, cyclesToPlot: cyclesToPlot, trimStart: trimStart, firstclick: firstclick,
      trimEnd: trimEnd, secondclick: secondclick, stats: stats, valueAxis: valueAxis, qualityTrim: qualityTrim
    };

  }

  public getSavedObject() {
    return this.object;
  }

  public eraseSavedObject() {
    this.object = undefined;
  }

  public getCurveofProgram(db: number, day: string, startTime: string, endTime: string,  sensor: string,programId:string) {



    let obj={"db":db,"day":day,"startTime":startTime,"endTime":endTime,"sensor":sensor,"programId":programId}

    //return this.httpClient.get<any[]>(`${this.apiURL}/getCurve/${db}/${day}/${startTime}/${endTime}/${cycleId}/${sensor}/${cycles}`);

    try{
    return this.httpClient.post<any[]>(`${this.apiURL}/getCurvesForProgram`,obj);
    }
    catch(exception ){
      console.log(exception)
    }
  }
  public getCycleofProgram(db: number,machineId: string,partNumberId, first: string, second: string,  programId:string) {


    if (programId == null) {
      programId = '0';
    }
    if(partNumberId==null)
    {
      partNumberId='0';
    }
    

    //return this.httpClient.get<any[]>(`${this.apiURL}/getCurve/${db}/${day}/${startTime}/${endTime}/${cycleId}/${sensor}/${cycles}`);

    try{
      return this.httpClient.get(`${this.apiURL}/loadCyclesForPrediction/${db}/${machineId}/${programId}/${first}/${second}`);
    }
    catch(exception ){
      console.log(exception)
    }
  }


}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//  "API_URL": "https://localhost:44356",
 "API_URL": "https://dde.jsp.com/API",

  "Prediction_URL":"http://ddepredictionstaging.jsp.com"//"http://localhost:5555"//"http://ddepredictionstaging.jsp.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<header>
  <span style="display: flex;justify-content:space-between; align-items:center;">
    <img src="assets/images/DDE_Logo_WOArpro.png" style="margin-left:52px; width:120px;height:60%">
  </span>

  <div   style=" height:100%;text-align: center;font-size: small; display:flex; flex-direction: column;justify-content: center;">
    <span id="customerTextBlock"> </span>
  </div>
  <span style="display:flex; height:60%;margin-right:30px">

    <kendo-dropdownlist class = "standart-input-height"  *ngIf="DBselectIsPresent" [textField]="'customerName'" [valueField]="'databaseId'"
      [data]="databaseItems" (valueChange)="selectedDatabaseChange($event)" [(ngModel)]="selectedDb">
    </kendo-dropdownlist>
    <img src="assets/images/{{this.customerLogo}}" style="margin-left:25px;width:100px; height:100%;">
  </span>
</header>


<div id="main_menu_block" closable="true" showOn="click" position="right" kendoTooltip filter=".li">
  <kendo-drawer-container id="main_menu">
    <kendo-drawer #drawer [items]="items" [mode]="'push'" [mini]="true"
     [expanded]="false" (select)="onSelect($event)"
      [title]="tooltip" (mouseover)="showTooltip($event)" style="position: fixed;height: 100%;padding-top:3%">
      
   </kendo-drawer>
    <kendo-drawer-content style="padding-left: 3.5%;padding-top: 1%;">

      <router-outlet></router-outlet>

   </kendo-drawer-content>
  </kendo-drawer-container>
</div>

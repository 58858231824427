
<div class="centred-controls">

  <kendo-dropdownlist  class = "standart-input-height"  [defaultItem]="defaultProjectValue"
  [valueField]="'item1'"

  style="padding-right:10px"
  (valueChange)="handleProjectChange($event)"

  [textField]="'item2'" >
  </kendo-dropdownlist>


  <kendo-dropdownlist  style="padding-right:10px" *ngIf="showMachine" class = "standart-input-height"  [defaultItem]="defaultMachineValue"

  (valueChange)="handleMachineChange($event)"
  [data]="machines"
  [valueField]="'item1'" [textField]="'item2'" >
  </kendo-dropdownlist>


  <kendo-datepicker  style="padding-right:10px" [format]="'dd/MM/yy'" *ngIf="showDate" class = "standart-input-height"

  (valueChange)="handleDateChange($event)"
  [readOnlyInput]="true"
  [(value)]="selectedDate" >
  </kendo-datepicker>

  <kendo-dropdownlist  style="padding-right:10px"  class = "standart-input-height" [data]="cycleNrs"   *ngIf="showCycleNr" [defaultItem]="defaultCycleNrValue"

  (valueChange)="handleCycleNrChange($event)"
  [valueField]="'item1'" [textField]="'item2'" >
  </kendo-dropdownlist>


  <kendo-dropdownlist   style="padding-right:10px" class = "standart-input-height"    *ngIf="showCycleNr"
  [data]="qAttributes"
  [defaultItem]="defaultQAttributeValue"
  [valueField]="'item1'" [textField]="'item2'" >
  </kendo-dropdownlist>


  <kendo-dropdownlist  style="padding-right:10px"
    class = "standart-input-height" *ngIf="showSensors"    [defaultItem]="defaultSensors"
  [valueField]="'item1'" [textField]="'item2'" >
  </kendo-dropdownlist>


  <input  style="margin-right:10px" kendoTextBox placeholder="Input ARPRO grade" />





  </div>


  <div class="centred-controls"   style="height:45px;margin-top:10px;margin-bottom:10px"><textarea style="height:45px;max-height:45px;padding-right:10px;width:400px" kendoTextArea  placeholder="Input comment">

  </textarea></div>



  <div class="centred-controls">
    <button style="margin-right:10px" class="k-button" (click)="generateReport()">
      Load
    </button>


    <button class="k-button" (click)="open()" style="margin-right:10px" (click)="saveAsATemplate()">
      Create report template
    </button>

    <kendo-dropdownlist  class = "standart-input-height"  [defaultItem]="'Select template'">
    </kendo-dropdownlist>

  </div>




  <div class="example-wrapper">

    <kendo-dialog title=" " style="margin: auto;"  *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="450">
        <p style="margin: 30px; text-align: center;">Template name:  <input  style="margin: auto;margin-right:10px" kendoTextBox placeholder="" /> </p>


        <kendo-dialog-actions>
            <button kendoButton (click)="close('yes')">Save</button>
        </kendo-dialog-actions>
    </kendo-dialog>
  </div>


  <div    *ngIf="showReport">

  <br>
  <h5 class='lines-on-sides-header'>
  Digital report
  </h5>

  <p style=" text-align: center;"> <img src="assets/images/ARPRO Logo cropped.png" style=" width:120px;height:30px"></p>
  <br>
  <p style=" text-align: center;"> Project: 4545  </p>
  <p style=" text-align: center;"> Machine: Eureka  </p>
  <p style=" text-align: center;"> ARPRO grade: 5122  </p>
  <p style=" text-align: center;"> Cycle number: 32  </p>
  <p style=" text-align: center;"> Date: 4 September 2020   </p>






    <p class='lines-on-sides-header' style=" text-align: center;"> Process data  </p>
    <p style=" text-align: center;"> <img src="assets/images/ddeSelectedSensorsStats.png" style=" width:100%;"></p>

    <p class='lines-on-sides-header' style=" text-align: center;"> Quality data  </p>
    <p style=" text-align: center;"> Width: maximum - 10.0,  minimum - 8.0,  target - 9.0, measured - <span   style="color:red" >7.4</span>   </p>
    <p style=" text-align: center;"> Height: maximum - 10.0,  minimum - 1.0,  target - 6.0, measured - <span   style="color:green" > 6.4 </span>    </p>
    <p class='lines-on-sides-header' style=" text-align: center;"> Comment   </p>
    <p  style=" text-align: center;"> ARPRO is an impact energy absorption material with a unique combination of properties. ARPRO is a 3D engineering material that delivers energy absorption with structural strength at very low weight, as well as chemical resistance, thermal and acoustic insulation, and is 100% recyclable. ARPRO has a significant and quantifiable environmental benefit that is twelve times its impact.

    </p>



    <div class="centred-controls">



    <button style="margin-right:10px" class="k-button" >
      Export
    </button>


  </div>


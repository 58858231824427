import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivePlotRange } from "../../../../../models/ActivePlotRange"
import { ColoursSchemeService } from 'src/app/services/colours-scheme.service';

@Component({
  selector: 'app-align-base',
  templateUrl: './align-base.component.html',
  styleUrls: ['./align-base.component.css']
})
export class AlignBaseComponent implements OnInit {

  constructor() {

    let coloursSchemeService = new ColoursSchemeService()
    this.colours = coloursSchemeService.getColours();
    this.cTimeColours = coloursSchemeService.getColoursForStats();
  }

  protected alignOnY;


  ngOnInit(): void {
    this.alignOnY = true;
  }


  public colours;
  public cTimeColours;


  activePlotRegion;
  @Input() set newActivePlotRegion(newVal) {
    this.activePlotRegion = newVal;

  }


  @Output() emitedPlotRegion = new EventEmitter<ActivePlotRange>();

  outputPanOrZoomEvent(newRegionForPlot: any) {
    this.emitedPlotRegion.emit(newRegionForPlot);
  }

}

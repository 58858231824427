import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { SharedWidgetsServicesModule } from '../modules/shared-widgets-services/shared-widgets-services.module';
import { partDescription, programDescription } from 'src/models/Configuration';
import { data } from 'jquery';

@Injectable({
  providedIn: SharedWidgetsServicesModule
})

export class ClientsInfrastructureService {


  clientsInfo: object;


  public getAllProjectsInDB(dbId: number, setter: (any) => void) {

    this.httpClient.get(`${environment.API_URL}/customerinfo/allprojects?databaseId=${dbId}`).subscribe(
        (data: any) => {

          setter(data);

        },
        (error: any) => {
          this.router.navigate(['login']);
        }
      );


  }

  public setAllProjects(dbId: number, machineId: number, locationId: number, setter: (any) => void) {
    console.log("setAllProjects", dbId, machineId, locationId);
    console.log("this.clientsInfo[dbId]", this.clientsInfo[dbId]);

    // if (this.clientsInfo[dbId] != undefined) {
    //   console.log("this.clientsInfo[dbId]", this.clientsInfo[dbId]);
    //   let location = this.clientsInfo[dbId].find(e => e.item1 == locationId);
    //   if (location == undefined || location.machines == undefined)
    //     return;

    //   let machine = location.machines.find(e => e.item1 == machineId);
    //   if (machine == undefined) return;

    //   this.httpClient.get(`${environment.API_URL}/customerinfo/projects?databaseId=${dbId}&machineId=${machine.item1}`).subscribe(
    //     (data: any) => {

    //       setter(data);

    //     },
    //     (error: any) => {
    //       this.router.navigate(['login']);
    //     }
    //   );
    // }
    if(dbId != undefined){
      if(locationId == undefined || machineId == undefined)
        return;

      if(machineId == undefined)
        return;

      this.httpClient.get(`${environment.API_URL}/customerinfo/projects?databaseId=${dbId}&machineId=${machineId}`).subscribe(

        (data: any) => {
            setter(data);

        },
        (error: any) => {
          this.router.navigate(['login']);
        }
        
      
      );
    }

  }


  public setParts(dbId: number , locationId:number,  machineId: number, projectId: number, setter: (any) => void)
  {

    // if (this.clientsInfo[dbId] != undefined) {

    //   let location = this.clientsInfo[dbId].find(e => e.item1 == locationId);
    //   if (location == undefined || location.machines == undefined)
    //     return;

    //   let machine = location.machines.find(e => e.item1 == machineId);
    //   if (machine == undefined) return;


    //   let q =`${environment.API_URL}/customerinfo/parts?databaseId=${dbId}&machineId=${machine.item1}`;

    //   if(projectId)
    //     q=q.concat(`&projectId=${projectId}`);


    //   this.httpClient.get(q).subscribe(
    //     (data: any) => {

    //       setter(data);

    //     },
    //     (error: any) => {
    //       this.router.navigate(['login']);
    //     }
    //   );

    // }

    if (dbId != undefined) {

       
        if (locationId == undefined || machineId == undefined)
          return;
  
       
        if (machineId == undefined) return;
  
  
        let q =`${environment.API_URL}/customerinfo/parts?databaseId=${dbId}&machineId=${machineId}`;
  
        if(projectId)
          q=q.concat(`&projectId=${projectId}`);
  
  
        this.httpClient.get(q).subscribe(
          (data: any) => {
  
            setter(data);
  
          },
          (error: any) => {
            this.router.navigate(['login']);
          }
        );
  
      }


  }

  public setAllPrograms(dbId: number, machineId: number, locationId: number, projectId: number, partId:number, setter: (any) => void) {

    // if (this.clientsInfo[dbId] != undefined) {

    //   let location = this.clientsInfo[dbId].find(e => e.item1 == locationId);
    //   if (location == undefined || location.machines == undefined)
    //     return;

    //   let machine = location.machines.find(e => e.item1 == machineId);
    //   if (machine == undefined) return;
    //   if (machine.programs == undefined) {

    //     let q = `${environment.API_URL}/customerinfo/programs?databaseId=${dbId}&machineId=${machine.item1}`;

    //     if (projectId != null)
    //       q = q.concat(`&projectId=${projectId}`);

    //       if (partId != null)
    //       q = q.concat(`&partId=${partId}`);

    //     this.httpClient.get(q).subscribe(
    //       (data: any) => {

    //         setter(data);

    //       },
    //       (error: any) => {
    //         this.router.navigate(['login']);
    //       }
    //     );


    //   }


    // }
    if(dbId != undefined){
      
      if(locationId == undefined || machineId == undefined)
        return;

      if(machineId == undefined)
        return;

      let q = `${environment.API_URL}/customerinfo/programs?databaseId=${dbId}&machineId=${machineId}`;

      if (projectId != null)
        q = q.concat(`&projectId=${projectId}`);

        if (partId != null)
        q = q.concat(`&partId=${partId}`);

      this.httpClient.get(q).subscribe(
        (data: any) => {

          setter(data);

        },
        (error: any) => {
          this.router.navigate(['login']);
        }
      );
    }

  }

  public setAllmachines(dbId: number, locationId: number, setter: (any) => void) {

    if (this.clientsInfo[dbId] != undefined) {
      let location = this.clientsInfo[dbId].find(el => el.item1 == locationId);
      if (location == undefined) return;
      if (location.machines == undefined) {
        this.httpClient.get(`${environment.API_URL}/customerinfo/machines?databaseId=${dbId}&locationId=${locationId}`).subscribe(
          (data: any) => {

            location.machines = data;
            setter(data);

          },
          (error: any) => {
            this.router.navigate(['login']);
          }
        );
      }
      else {
        setter(location.machines);
      }
    }

  }

  public setAllLocations(dbId: number, setter: (any) => void) {
    if (this.clientsInfo[dbId] == undefined) {
      
      this.httpClient.get(`${environment.API_URL}/customerinfo/locations?databaseId=${dbId}`).subscribe(
        (data: any) => {

          this.clientsInfo[dbId] = data;
          setter(data);

        },
        (error: any) => {
          this.router.navigate(['login']);

        }

      );
    }
    else
      setter(this.clientsInfo[dbId]);

  }


  public getPartsfromProject(dbId:number,projectId:number,setter: (any) => void){
    this.httpClient.get(`${environment.API_URL}/customerinfo/allparts?databaseId=${dbId}&projectId=${projectId}`).subscribe(
      (data: any) => {

        setter(data);

      },
      (error: any) => {
        this.router.navigate(['login']);
      }
    );

  }
  
  public setPartsfromProject(dbId:number,partInfo:partDescription,setter: (any) => void){
   // let headers = new HttpHeaders().set('Content-Type', 'application/json'); 
    partInfo.dbId=dbId;
    
    this.httpClient.post(`${environment.API_URL}/customerinfo/setPartConfiguration`,partInfo).subscribe((data: any) => {
        
      setter(data);

    },
    (error: any) => {
      this.router.navigate(['login']);
    
    });
    

  }

  public getProgramsForProject(dbId:number,projectId:number,setter: (any) => void){
    this.httpClient.get(`${environment.API_URL}/customerinfo/allprograms?databaseId=${dbId}&projectId=${projectId}`).subscribe(
      (data: any) => {

        setter(data);

      },
      (error: any) => {
        this.router.navigate(['login']);
      }
    );
    

  }
  
  public setProgramsfromProject(dbId:number,programInfo:programDescription,setter: (any) => void){
    
    programInfo.dbId=dbId;
    
    this.httpClient.post(`${environment.API_URL}/customerinfo/setProgramConfiguration`,programInfo).subscribe((data: any) => {
      setter(data);

    },
    (error: any) => {
      this.router.navigate(['login']);
    
    });
    

  }

  public setDefaultSelection(dbId:number,userId:number,locationId:number,machineId:number,setter: (any) => void){
    
    
    this.httpClient.post(`${environment.API_URL}/userConfig/SaveDefaultUserSelection`,{"dbId":dbId,"userId": userId,"locationId":locationId,"machineId":machineId} ).subscribe((data: any) => {
      setter(data);

    },
    (error: any) => {
     // this.router.navigate(['login']);
    
    });
    

  }

  public getDefaultSelection(dbId:number,userId:number,setter: (any) => void){
    this.httpClient.get(`${environment.API_URL}/userConfig/getDefaultUserSelection/${dbId}/${userId}`).subscribe(
      (data: any) => {

        setter(data);

      },
      (error: any) => {
        //this.router.navigate(['login']);
      }
    );

  }

  public getAllProjectsPartsPrograms(dbId: number, machineId: number, setter: (any) => void) {

    this.httpClient.get(`${environment.API_URL}/customerinfo/projectspartsprograms?databaseId=${dbId}&machineId=${machineId}`).subscribe(
  
        (data: any) => {
          setter(data);

        },
        (error: any) => {
          this.router.navigate(['login']);
        }
      );
    }

  constructor(private httpClient: HttpClient, private router: Router) {
    this.clientsInfo = new Object;
  }
}

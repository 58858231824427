import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { DisplayViewComponent } from './components/display-view/display-view.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { NoPageComponent } from './components/no-page/no-page.component';
import { AuthGuardService } from './services/auth-guard.service';



const routes: Routes = [
  { path: '', component: MainMenuComponent, canActivate: [AuthGuardService] },
  { path: 'login', component: LoginComponent },
  { path: 'pages/Display View', component: DisplayViewComponent, canActivate: [AuthGuardService] },
  {
    path: 'pages', component: MainMenuComponent, canActivate: [AuthGuardService],



    children: [
      {
        path: 'Stats', loadChildren: () => import('./modules/stats/stats-daschboard.module')
          .then(m => m.StatsDashboardModule), canActivate: [AuthGuardService]
      },
      {
        path: 'Curve', loadChildren: () => import('./modules/trim/trim.module')
          .then(m => m.TrimModule), canActivate: [AuthGuardService]
      },
      {
        path: 'Configuration',
        loadChildren: () => import('./modules/configuration/configuration.module')
          .then(m => m.ConfigurationModule), canActivate: [AuthGuardService]
      },
      {
        path: 'Configuration/:id',
        loadChildren: () => import('./modules/configuration/configuration.module')
          .then(m => m.ConfigurationModule), canActivate: [AuthGuardService]
      },
      {
        path: 'Reporting', loadChildren: () => import('./modules/individual-reports/individual-reports.module')
          .then(m => m.IndividualReportsModule), canActivate: [AuthGuardService]
      },
      {
        path: 'MServices', loadChildren: () => import('./modules/manage-service/manage-service.module')
          .then(m => m.ManageServiceModule), canActivate: [AuthGuardService]
      }
      ,{
        path:'Process-Coridor',loadChildren:()=>import('./modules/prediction-coridor/prediction-coridor.module')
        .then(p=>p.PredictionCoridorModule),canActivate:[AuthGuardService]
      },{
        path:'OEE',loadChildren:()=>import('./modules/oee/oee.module')
        .then(p=>p.OEEModule),canActivate:[AuthGuardService]
      },
      {
      path:'QualityControl',loadChildren:()=>import('./modules/automated-quality-control/automated-quality-control.module')
        .then(p=>p.AutomatedQualityControlModule),canActivate:[AuthGuardService]
      }
    ]


  },
  { path: '**', component: NoPageComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

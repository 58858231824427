import { Injectable } from '@angular/core';
import { SharedServicesModule } from '../modules/shared-services/shared-services.module';

@Injectable({
  providedIn: SharedServicesModule
})
export class DataRangeManagementService {

  constructor() { }



 public  calculateStartOfInverval(end: Date, milliseconds: number): Date {

    var modifiedDate = new Date(end);

    modifiedDate.setMilliseconds(0);
    modifiedDate.setSeconds(0);
    modifiedDate.setMinutes(0);
    modifiedDate.setHours(0);

    let start = modifiedDate.getTime() - milliseconds;

    return new Date(start);
  }




 public  calculateEndOfInterval(start: Date) :Date{


  var modifiedDate = new Date(start);

  modifiedDate.setMilliseconds(999);
  modifiedDate.setSeconds(59);
  modifiedDate.setMinutes(59);
  modifiedDate.setHours(23);

    return modifiedDate;
  }




}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { ActivePlotRange } from '../../../../../models/ActivePlotRange'
import { AlignBaseComponent } from '../align-base/align-base.component';

import { DataRangeManagementService } from "../../../../services/data-range-management.service"
import { ZoomEvent } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-base-period-based-plot',
  templateUrl: './base-period-based-plot.component.html',
  styleUrls: ['./base-period-based-plot.component.css']
})
export class BasePeriodBasedPlotComponent extends AlignBaseComponent implements OnInit {

  constructor(private dataRangeManagementService: DataRangeManagementService) {
    super();
  }


  public minX;
  public maxX;
  public minY;
  public maxY;


  public seriesData = [];

  public defaultPeriod: ActivePlotRange = { minX: undefined, maxX: undefined, minY: 0, maxY: 1, step: 3 }
  public step = 3;
  public stepY;



  //update min, max and step when an update is being emitted
  @Input() set newActivePlotRegion(newRegion) {

    if (newRegion != undefined) {

      if (newRegion.minX != undefined) this.minX = newRegion.minX;
      if (newRegion.maxX != undefined) this.maxX = newRegion.maxX;
      if (this.alignOnY) {
        if (newRegion.minY != undefined) this.minY = newRegion.minY;
        if (newRegion.maxY != undefined) this.minY = newRegion.maxY;
      }

      if (newRegion.step != undefined) this.step = newRegion.step;
    }

  }



endX;
startX;


  protected setData(data, maxY, minY, maxX, minX, preserveZoom:boolean=false)
   {

    if (!(data == null || data.length == 0)) {
      let offset = (maxY - minY) * 0.1;
      if (offset == 0)
        offset = 0.1
      this.minY = this.roundMinMaxLimits(minY - offset, true);
      this.maxY = this.roundMinMaxLimits(maxY + offset, false);

    }
    else {

      if (data == null)
        data = [];

      this.minY = this.defaultPeriod.minY;
      this.maxY = this.defaultPeriod.maxY;

    }

    if (!preserveZoom )
    {
      this.minX = minX;
      this.maxX = maxX;
    }



    this.endX= maxX;
    this.startX= minX

    this.step = this.calculateStep(minX, maxX);

    this.stepY= this.calculateStepNumericAxis(this.minY,this.maxY )
    this.seriesData = data;

    this.emitRange(this.minX, this.maxX, this.minY, this.maxY, this.step);

  }


  //calculate periods between labels
  protected calculateStep(start: Date, end: Date): number {

    let periodLength = end.getTime() - start.getTime();

    //6 intervals
    let step = (periodLength / 6) / 3600000;

    return Math.ceil(step);
  }


  protected calculateStepNumericAxis(min: number, max: number): number
   {

    let periodLength = max-min;
    if(periodLength===0)
    return 0.25;
    else

    return (periodLength / 4);

   }


  roundMinMaxLimits(val: number, isMinLimit: boolean): number {
    var rounded = Math.round(val);
    if (isMinLimit) {
      if (rounded > val && !(rounded == 0 && val > 0))
        rounded = rounded - 1;
    }
    else {
      if (rounded < val)
        rounded = rounded + 1;
    }
    return rounded;
  }


  protected emitRange(minX, maxX, minY, maxY, step) {

    this.outputPanOrZoomEvent({
      "maxX": maxX,
      "minX": minX,
      "maxY": maxY,
      "minY": minY,
      "step": step
    })
  }



  processPanOrZoom(ev) {

    if (ev.axisRanges != null) {


      if (ev.axisRanges.xAxis == null) {

        ev.axisRanges.xAxis = { "min": this.startX, "max": this.endX }

      }



      if (ev.axisRanges.yAxis == null) {

        ev.axisRanges.yAxis = { "min": this.minY, "max": this.maxY }
      }



      this.emitRange(ev.axisRanges.xAxis.min, ev.axisRanges.xAxis.max,
        ev.axisRanges.yAxis.min, ev.axisRanges.yAxis.max, this.step);
      
      this.minX = ev.axisRanges.xAxis.min
      this.maxX = ev.axisRanges.xAxis.max
    }

  }



  onDrag(ev) {

    if (this.seriesData == null || this.seriesData.length == 0)
      ev.prevented = true;

  }


  onZoom(ev:ZoomEvent) {

    if( ev.delta>0)
    {
    if (ev.axisRanges.xAxis != null) {

        ev.axisRanges.xAxis.max= this.endX;
          ev.axisRanges.xAxis.min=this.startX

        }

    }

  }


  public onDragEnd(ev) {
   this.processPanOrZoom(ev);

  }


  public onZoomEnd(ev) {
    this.processPanOrZoom(ev);
  }

  calculateStartOfInverval(end: Date, milliseconds: number): Date {
    return this.dataRangeManagementService.calculateStartOfInverval(end, milliseconds);

  }





  public calculateEndOfInterval(start: Date): Date {

    return this.dataRangeManagementService.calculateEndOfInterval(start);
  }


  ngOnInit(): void {
  }


  public axisCrossingValue = [Number.NEGATIVE_INFINITY]


}

import { Injectable } from '@angular/core';
import { HttpClientErrorHandlingService } from '../services/http-err-handling.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { QualityMeasurement } from 'src/models/Configuration';
import { DateTimePickerActiveTab } from '@progress/kendo-angular-dateinputs';

@Injectable({
  providedIn: 'root'
})


export class QualityDataLoadingService {




  public setUnitsForQData(dbId: number, machineId: number, start: Date, end: Date, setter: (data) => void) {
    let q = `${environment.API_URL}/qualitydata/getSelectedUnits?databaseId=${dbId}&machineId=${machineId}&start=${start.toUTCString()}&end=${end.toUTCString()}`;


    this.httpClient.get(q).subscribe(
      (data: any) => {
        setter(data);
      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      });

  }

  public setUnitsForQDataForDisplay(setter: (data) => void) {
    let q = `${environment.API_URL}/qualitydata/getSelectedUnits`;


    this.httpClient.get(q).subscribe(
      (data: any) => {
        setter(data);
      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      });

  }



  public setSelectedUnitsForQData(dbId: number, machineId: number, setter: (data) => void) {
    let q = `${environment.API_URL}/qualitydata/Units?databaseId=${dbId}&machineId=${machineId}`;


    this.httpClient.get(q).subscribe(
      (data: any) => {
        setter(data);
      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      });

  }




  /*
          https://localhost:44346/QualityData/QualityPerCycle?databaseId=2&qualityAttributes[0]=Height&qualityAttributes[1]=Width&qualityAttributes[2]=Length&qualityId=53421
      */


  public setQDataPerCycle(requestedQAttributes: string[], dbId: number, cycleId: number, qualityId: number, partId: number,
    setter: (targetStorage) => void): any {

    let q = `${environment.API_URL}/qualitydata/QualityPerCycle?databaseId=${dbId}`

    if (qualityId != null)
      q = q.concat(`&qualityId=${qualityId}`)
    if (cycleId != null)
      q = q.concat(`&cycleId=${cycleId}`)
    if (partId != null)
      q = q.concat(`&partId=${partId}`)




    requestedQAttributes.forEach((el, idx) => {
      q = q.concat(`&qualityAttributes[${idx}]=${el}`)
    })

    this.httpClient.get(q).subscribe(
      (data: any) => {
        setter(data);
      },
      (error: any) => {

        this.httpErrHandlingService.process(error);
      });

  }



  public setQDataPerTimeInterval(qualityDataType: string, dbId: number, machineId: number,
    end: Date, start: Date, projectId: number, programId: number, partId: number,
    setter: (arg1, arg2, arg3, startTimeArg, endTimeArg) => void) {

    //let q = `${environment.API_URL}/qualitydata/${qualityDataType}?databaseId=${dbId}&machineId=${machineId}&end=${end.toUTCString()}&start=${start.toUTCString()}`
    let q = `${environment.API_URL}/qualitydata/GetAttributeQualityData?qualityType=${qualityDataType}&databaseId=${dbId}&machineId=${machineId}&end=${end.toUTCString()}&start=${start.toUTCString()}`

    if (projectId != null)
      q = q.concat(`&projectId=${projectId}`)

    if (programId != null)
      q = q.concat(`&programId=${programId}`)

    if (partId != null)
      q = q.concat(`&partId=${partId}`)



    this.httpClient.get(q).subscribe(
      (data: any) => {

        let minQ = Number.POSITIVE_INFINITY;
        let maxQ = Number.NEGATIVE_INFINITY;
        let startTime = null;
        let endTime = null;
        let cview = null;
        if (data.length != 0) {
          startTime = new Date(data[0].time);
          endTime = new Date(data[data.length - 1].time);
        }

        let lastReadingTime = new Date(-8640000000000000); //min time
        setter(data.map(item => {


          if (item.actualValue != null) {
            minQ = Math.min(minQ, item.actualValue);
            maxQ = Math.max(maxQ, item.actualValue);
          }

          if (item.min != null) {
            minQ = Math.min(minQ, item.min);
            maxQ = Math.max(maxQ, item.min);
          }

          if (item.max != null) {
            minQ = Math.min(minQ, item.max);
            maxQ = Math.max(maxQ, item.max);
          }

          if (item.target != null) {
            minQ = Math.min(minQ, item.target);
            maxQ = Math.max(maxQ, item.target);
          }
          if (item.number != null) {
            cview = item.number;
          }



          let dateTimeOfReading: Date = new Date(item.time)
          let dateTimeOfReadingTimestamp = dateTimeOfReading.getTime()
          if (lastReadingTime.getTime() === dateTimeOfReadingTimestamp) {
            //add second so that all dates are unique
            dateTimeOfReadingTimestamp += 1000;
            dateTimeOfReading = new Date(dateTimeOfReadingTimestamp);

          }
          item.time = dateTimeOfReading;
          lastReadingTime = item.time;
          return item;
        }), minQ, maxQ, startTime, endTime);

      },
      (error: any) => {
        this.httpErrHandlingService.process(error);
      }

    );


  }




  constructor(private httpClient: HttpClient, private httpErrHandlingService: HttpClientErrorHandlingService) { }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'menu-pages',
  templateUrl: './menu-pages.component.html',
  styleUrls: ['./menu-pages.component.css']
})
export class MenuPagesComponent implements OnInit {

  @Input() selectedItem: string;
  selectedDbId

  @Input()
  set currentDb(currentDb: any) {
    this.selectedDbId = currentDb.databaseId;
  }

  reportConfig;
  @Input()
  set currentReportConfig(config: any) {
    if (config)
      this.reportConfig = config;
  }


  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit,Input } from '@angular/core';
import { ClientsInfrastructureService } from 'src/app/services/clients-infrastructure.service';

@Component({
  selector: 'app-mockup',
  templateUrl: './mockup.component.html',
  styleUrls: ['./mockup.component.css']
})
export class MockupComponent implements OnInit {

  constructor(private clientsInfrastructureService:ClientsInfrastructureService) { }

  ngOnInit(): void {
  }

  handeConfigurationChange(newConf)
  {


  }



  currentDbId;
  @Input()
  set currentDb(currentDbId: number) {

    this.currentDbId = currentDbId;

  }

projects;



loadProjects()
{

  //clientsInfrastructureService
}




locations
currentMachine
currentLocation


loadLocation(dbId)
{


   this.clientsInfrastructureService.setAllLocations(dbId, (locationsData) => {
      this.locations = locationsData;

      if (this.locations == null || locationsData.length == 0) {

        this.machines = null;
        this.currentMachine = null;
        this.currentLocation = null;
        return;
      }

      this.currentLocation = this.locations[0];
      this.updateMachines(this.currentDbId, this.currentLocation.item1);
    })

}



updateMachines(dbId: number, locationId: number) {

  this.clientsInfrastructureService.setAllmachines(dbId, locationId, (data) => {
    this.machines = data;
    if (this.machines == null || this.machines.length == 0) {

      this.currentMachine = null;
          return;
    }
    this.currentMachine = this.machines[0];

    //this.updateAvailableDays(dbId, this.currentMachine.item1);
  });
}




  defaultProjectValue= {item1:null, item2:"Select project"}

  defaultMachineValue= {item1:null, item2:"Select machine"}

  defaultCycleNrValue= {item1:null, item2:"Select cycle number"}


  defaultSensors={item1:null, item2:"Select sensor/setpoint(s)"}


  defaultQAttributeValue= {item1:null, item2:"Select quality attribute(s)"}

showReport=false;

  showProject=true;
  showDate=true;
  showMachine=true;
  showCycleNr= false;
showSensors= false;

machine=null;
selectedDate;

qAttributes= [ {item1:1, item2:"Width"}, {item1:1, item2:"Height"}]


cycleNrs= [ {item1:1, item2:"1"}, {item1:1, item2:"2"}]
machines=[ {item1:1, item2:"Eureka"}, {item1:1, item2:"Cheb Lab"}]

  handleCycleNrChange(val)
  {

    this.showSensors=(val!=null);
  }


  handleProjectChange(val)
  {


  }


  saveAsATemplate()
  {

  }

  handleDateChange(val)
  {

    this.selectedDate= val;
    this.showCycleNr= (this.selectedDate!=null && this.machine.item1!=null);
  }

  generateReport()
  {
    this.showReport=true
  }



  handleMachineChange(val)
  {
    this.machine= val;
    this.showCycleNr= (this.selectedDate!=null && this.machine.item1!=null);


  }

  public opened = false;



    public close(status) {

      this.opened = false;
    }

    public open() {

      this.opened = true;
    }





}

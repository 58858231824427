import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewChild, HostListener, Inject, ElementRef } from '@angular/core';
import { Trim } from 'src/models/Trim'
import { DashboardService } from 'src/app/services/dashboard.service';
import { Configuration, cycleTimeMeasurement, QualityMeasurement } from 'src/models/Configuration';
import { QualityTrim } from 'src/models/QualityTrim';
import { DatePipe, DOCUMENT } from '@angular/common';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { element } from 'protractor';
import { PlotAreaClickEvent, LegendItemVisualArgs, ChartComponent, CategoryAxisNotesLabelComponent, SeriesHighlight, ValueAxisLabels } from '@progress/kendo-angular-charts';
import { Path, Group, Text, Element } from '@progress/kendo-drawing';
import { Observable, of } from 'rxjs';
import { filter, retry } from 'rxjs/operators';
import { BasePeriodBasedPlotComponent } from '../for-pages/for-trim-view-page/base-period-based-plot/base-period-based-plot.component';
import { DataRangeManagementService } from 'src/app/services/data-range-management.service';
import { ProcessDataLoadingService } from 'src/app/services/process-data-loading.service';
import { ColoursSchemeService } from 'src/app/services/colours-scheme.service';
import { QualityDataLoadingService } from 'src/app/services/quality-data-loading.service';
import { Router } from '@angular/router';
import { AppStateService } from 'src/app/services/app-state.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-display-view',
  templateUrl: './display-view.component.html',
  styleUrls: ['./display-view.component.css'],
  providers: [DatePipe]
})
export class DisplayViewComponent extends BasePeriodBasedPlotComponent implements OnInit, AfterViewInit {
  unitsText: string;
  @Input() set currentConfiguration(newConf: any) {
    this.configuration = newConf;
  }
  cycleTime: any = [{ text: 'Cycle Time', unit: 's' }];
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.sendTypingEvent(event);
  }
  @ViewChild('performanceDataRef') pRef!: ChartComponent;
  @Output() goToAggregation = new EventEmitter<boolean>();
  @Output() trim = new EventEmitter<Trim>();
  @Output() selectedReadings = new EventEmitter<any[]>();
  trimStart: number;
  trimEnd: number;
  configuration: any;
  qualityTrim: QualityTrim;
  curveView: boolean;
  sensorsConfig;
  measurements;
  cycles;
  selectedSensors: any[] = [];
  selectedCycles: any[] = [];
  selectedShiftCycles: any[];
  selectedDays: any[];
  seriesColor: any[];
  valueAxes: any[] = [];
  isZoomed: boolean = false;
  cyclesToPlot: any[];
  cyclesToPlotChanges: boolean;
  sensorsToPlot: any[];
  sensorsToPlotChanges: boolean;
  enableSwitch: boolean = false;
  newPlot: any[] = [];
  ValuesToplot: any[];
  public aggregatedValues: any = [];
  public plot: any[] = [];
  public windowTop = 400;
  public windowLeft = 2000;
  public windowwidth = 280;
  public windowHeight = 280;
  isWhatPercentOf(numA, numB) {
    return (numB / 100) * numA;
  }
  changepostionofSensors() {


    let id1 = document.getElementById('id1');
    let id2 = document.getElementById('id2');
    let id3 = document.getElementById('id3');
    let id4 = document.getElementById('id4');
    let id5 = document.getElementById('id5');
    let id6 = document.getElementById('id6');
    let id7 = document.getElementById('id7');
    let id8 = document.getElementById('id8');
    let id9 = document.getElementById('id9');
    let id10 = document.getElementById('id10');
    let kwindow = document.getElementsByClassName('k-window') as HTMLCollectionOf<HTMLElement>;

    if (window.innerHeight >= 1001) {


      if (window.innerWidth >= 2401) {

      
        this.valueAxisLabelsYCycle = {
          format: 'HH:mm',
          step: 1,
          font: 'bold 250% Arial, sans-serif',
        };
        this.valueAxisLabelsXCycle = {
          step: 0.5,
          format: 'n1',
          rotation: 'auto',
          font: 'bold 250% Arial, sans-serif'
        };
        this.valueAxisLabelsXCurve = {
          font: 'bold 250% Arial, sans-serif'
        };

        this.windowwidth = 310;//this.isWhatPercentOf(12, window.innerWidth);
        this.windowHeight = 280;//this.isWhatPercentOf(20, window.innerHeight);
        this.windowTop = this.isWhatPercentOf(35, window.innerHeight);
        this.windowLeft = this.isWhatPercentOf(80, window.innerWidth);

        id1.style.fontSize = "250%";
        id2.style.fontSize = "250%";
        id3.style.fontSize = "250%";
        id4.style.fontSize = "250%";
        id5.style.fontSize = "250%";
        id6.style.fontSize = "250%";
        id7.style.fontSize = "250%";
        id8.style.fontSize = "250%";
        id9.style.fontSize = "250%";
        id10.style.fontSize = "250%";
        if (kwindow.length != 0) {
          kwindow[0].style.fontSize = "225%";
        }

        this.titleofvalueAxisLabelsYCurve = "{text: 'sec',position: 'right',font:'bold 250%  Arial, sans-serif'}";
      }
      else if (window.innerWidth <= 2400) {
        
        this.valueAxisLabelsYCycle = {
          format: 'HH:mm',
          step: 1,
          font: 'bold 200% Arial, sans-serif',
        };
        this.valueAxisLabelsXCycle = {
          step: 0.5,
          format: 'n1',
          rotation: 'auto',
          font: 'bold 200% Arial, sans-serif'
        };
        this.valueAxisLabelsXCurve = {
          font: 'bold 200% Arial, sans-serif'
        };







        this.windowwidth = 307;//this.isWhatPercentOf(12, window.innerWidth);
        this.windowHeight = 260;//this.isWhatPercentOf(20, window.innerHeight);
        this.windowTop = this.isWhatPercentOf(38, window.innerHeight);
        this.windowLeft = this.isWhatPercentOf(80, window.innerWidth);

        id1.style.fontSize = "175%";
        id2.style.fontSize = "175%";
        id3.style.fontSize = "175%";
        id4.style.fontSize = "175%";
        id5.style.fontSize = "175%";
        id6.style.fontSize = "175%";
        id7.style.fontSize = "175%";
        id8.style.fontSize = "175%";
        id9.style.fontSize = "175%";
        id10.style.fontSize = "175%";
        if (kwindow.length != 0) {
          kwindow[0].style.fontSize = "125%";
        }

        this.titleofvalueAxisLabelsYCurve = "{text: 'sec',position: 'right',font:'bold 200%  Arial, sans-serif'}";
      }

    }
    else if (window.innerWidth <= 950 && window.innerHeight <= 450) {
      
      this.valueAxisLabelsYCycle = {
        format: 'HH:mm',
        step: 1,
        font: 'bold 50% Arial, sans-serif',
      };
      this.valueAxisLabelsXCycle = {
        step: 0.5,
        format: 'n1',
        rotation: 'auto',
        font: 'bold 50% Arial, sans-serif'
      };
      this.valueAxisLabelsXCurve = {
        font: 'bold 50% Arial, sans-serif'
      };

      this.windowwidth = 100;//this.isWhatPercentOf(12, window.innerWidth);
      this.windowHeight = 100;//this.isWhatPercentOf(20, window.innerHeight);
      this.windowTop = this.isWhatPercentOf(38, window.innerHeight);
      this.windowLeft = this.isWhatPercentOf(80, window.innerWidth);

      id1.style.fontSize = "50%";
      id2.style.fontSize = "50%";
      id3.style.fontSize = "50%";
      id4.style.fontSize = "50%";
      id5.style.fontSize = "50%";
      id6.style.fontSize = "50%";
      id7.style.fontSize = "50%";
      id8.style.fontSize = "50%";
      id9.style.fontSize = "50%";
      id10.style.fontSize = "50%";
      if (kwindow.length != 0) {
        kwindow[0].style.fontSize = "50%";
      }

      this.titleofvalueAxisLabelsYCurve = "{text: 'sec',position: 'right',font:'bold 50%  Arial, sans-serif'}";


    }

    else if (window.innerHeight <= 1000) {

      if (window.innerWidth >= 1701) {
      
        this.valueAxisLabelsYCycle = {
          format: 'HH:mm',
          step: 1,
          font: 'bold 200% Arial, sans-serif',
        };
        this.valueAxisLabelsXCycle = {
          step: 0.5,
          format: 'n1',
          rotation: 'auto',
          font: 'bold 200% Arial, sans-serif'
        };
        this.valueAxisLabelsXCurve = {
          font: 'bold 200% Arial, sans-serif'
        };

        this.windowwidth = 307;//this.isWhatPercentOf(12, window.innerWidth);
        this.windowHeight = 260;//this.isWhatPercentOf(20, window.innerHeight);
        this.windowTop = this.isWhatPercentOf(35, window.innerHeight);
        this.windowLeft = this.isWhatPercentOf(80, window.innerWidth);

        let id10 = document.getElementById('id10');
        id1.style.fontSize = "175%";
        id2.style.fontSize = "175%";
        id3.style.fontSize = "175%";
        id4.style.fontSize = "175%";
        id5.style.fontSize = "175%";
        id6.style.fontSize = "175%";
        id7.style.fontSize = "175%";
        id8.style.fontSize = "175%";
        id9.style.fontSize = "175%";
        id10.style.fontSize = "175%";
        if (kwindow.length != 0) {
          kwindow[0].style.fontSize = "125%";
        }

        this.titleofvalueAxisLabelsYCurve = "{text: 'sec',position: 'right',font:'bold 200%  Arial, sans-serif'}";
      }
      else if (window.innerWidth <= 1700) {
      
        this.valueAxisLabelsYCycle = {
          format: 'HH:mm',
          step: 1,
          font: 'bold 125% Arial, sans-serif',
        };
        this.valueAxisLabelsXCycle = {
          step: 0.5,
          format: 'n1',
          rotation: 'auto',
          font: 'bold 125% Arial, sans-serif'
        };
        this.valueAxisLabelsXCurve = {
          font: 'bold 125% Arial, sans-serif'
        };

        this.windowwidth = 180;//this.isWhatPercentOf(12, window.innerWidth);
        this.windowHeight = 180;//this.isWhatPercentOf(20, window.innerHeight);
        this.windowTop = this.isWhatPercentOf(38, window.innerHeight);
        this.windowLeft = this.isWhatPercentOf(80, window.innerWidth);

        id1.style.fontSize = "100%";
        id2.style.fontSize = "100%";
        id3.style.fontSize = "100%";
        id4.style.fontSize = "100%";
        id5.style.fontSize = "100%";
        id6.style.fontSize = "100%";
        id7.style.fontSize = "100%";
        id8.style.fontSize = "100%";
        id9.style.fontSize = "100%";
        id10.style.fontSize = "100%";
        if (kwindow.length != 0) {
          kwindow[0].style.fontSize = "50%";
        }

        this.titleofvalueAxisLabelsYCurve = "{text: 'sec',position: 'right',font:'bold 175%  Arial, sans-serif'}";

      }

    }



  }


  changeStepWithCycleTime(value) {
   
    if (window.innerHeight >= 1001) {


      if (window.innerWidth >= 2401) {


        if (value >= 1000) {

          this.valueAxisLabelsYCurve = {
            step: 200, font: 'bold 250% Arial, sans-serif'
          };
        }
        else if (value < 1000 && value > 500) {

          this.valueAxisLabelsYCurve = {
            step: 100, font: 'bold 250% Arial, sans-serif'
          };
        }
        else if (value < 400) {

          this.valueAxisLabelsYCurve = {
            step: 50, font: 'bold 250% Arial, sans-serif'
          };
        }

      }

      else if (window.innerWidth <= 2400) {


        if (value >= 1000) {

          this.valueAxisLabelsYCurve = {
            step: 200, font: 'bold 200% Arial, sans-serif'
          };
        }
        else if (value < 1000 && value > 500) {

          this.valueAxisLabelsYCurve = {
            step: 100, font: 'bold 200% Arial, sans-serif'
          };
        }
        else if (value < 400) {

          this.valueAxisLabelsYCurve = {
            step: 50, font: 'bold 200% Arial, sans-serif'
          };
        }



      }

    }

    else if (window.innerWidth <= 950 && window.innerHeight <= 450) {
      if (value >= 1000) {

        this.valueAxisLabelsYCurve = {
          step: 200, font: 'bold 100% Arial, sans-serif'
        };
      }
      else if (value < 1000 && value > 500) {

        this.valueAxisLabelsYCurve = {
          step: 100, font: 'bold 100% Arial, sans-serif'
        };
      }
      else if (value < 400) {

        this.valueAxisLabelsYCurve = {
          step: 50, font: 'bold 100% Arial, sans-serif'
        };
      }


    }

    else if (window.innerHeight <= 1000) {
      if (window.innerWidth >= 1701) {
        if (value >= 1000) {

          this.valueAxisLabelsYCurve = {
            step: 200, font: 'bold 200% Arial, sans-serif'
          };
        }
        else if (value < 1000 && value > 500) {

          this.valueAxisLabelsYCurve = {
            step: 100, font: 'bold 200% Arial, sans-serif'
          };
        }
        else if (value < 400) {

          this.valueAxisLabelsYCurve = {
            step: 50, font: 'bold 200% Arial, sans-serif'
          };
        }



      }
      else if (window.innerWidth <= 1700) {

        if (value >= 1000) {

          this.valueAxisLabelsYCurve = {
            step: 200, font: 'bold 125% Arial, sans-serif'
          };
        }
        else if (value < 1000 && value > 500) {

          this.valueAxisLabelsYCurve = {
            step: 100, font: 'bold 125% Arial, sans-serif'
          };
        }
        else if (value < 400) {

          this.valueAxisLabelsYCurve = {
            step: 50, font: 'bold 125% Arial, sans-serif'
          };
        }


      }



    }
  }

  public valueAxisLabelsXCycle: ValueAxisLabels = {
    format: 'HH:mm',
    step: 1,
    font: 'bold 26px Arial, sans-serif',
  };
  public valueAxisLabelsYCycle: ValueAxisLabels = {
    step: 0.5,
    format: 'n1',
    rotation: 'auto',
    font: 'bold 26px Arial, sans-serif'
  };


  public valueAxisLabelsYCurve: ValueAxisLabels = {
    step: 50, font: 'bold 26px Arial, sans-serif'
  };
  public valueAxisLabelsXCurve: ValueAxisLabels = {
    font: 'bold 26px Arial, sans-serif'
  };
  public titleofvalueAxisLabelsXCurve: ValueAxisLabels = {
    font: 'bold 26px Arial, sans-serif'
  };
  public titleofvalueAxisLabelsYCurve: string = "{text: 'sec',position: 'right',font:'bold 150%  Arial, sans-serif'}";


  groupedCycles: GroupResult[];
  days: Array<string> = [];
  groupedPlot: GroupResult[];
  popupLegend: boolean = false;
  chartLabelTime: number = 0;
  axisCrossingValue = [Number.NEGATIVE_INFINITY];
  stats: any[] = [
    { id: 0, sensor: null, max: "...", min: "...", avg: "...", config: {} },
    { id: 1, sensor: null, max: "...", min: "...", avg: "...", config: {} },
    { id: 2, sensor: null, max: "...", min: "...", avg: "...", config: {} },
    { id: 3, sensor: null, max: "...", min: "...", avg: "...", config: {} },
    { id: 4, sensor: null, max: "...", min: "...", avg: "...", config: {} },
    { id: 5, sensor: null, max: "...", min: "...", avg: "...", config: {} }
  ];
  activeView: { isSelected: boolean, text: string, css: string, dbField: string, unit: string, calculatedFieldName: string };
  buttons = [{ isSelected: true, text: 'Cycle time', css: '', dbField: 'cycleTime', unit: 's', calculatedFieldName: undefined },
  { isSelected: false, text: 'Air consumption', css: 'hidden_el', dbField: 'airConsumption', unit: 'm³', calculatedFieldName: undefined },
  { isSelected: false, text: 'Water consumption', css: 'hidden_el', dbField: 'waterConsumption', unit: 'm³', calculatedFieldName: undefined },
  { isSelected: false, text: 'Steam consumption', css: 'hidden_el', dbField: 'steamConsumption', unit: 'kg', calculatedFieldName: undefined },
  { isSelected: false, text: 'Electricity consumption', css: 'hidden_el', dbField: 'electricityConsumption', unit: 'kWh', calculatedFieldName: undefined },
  { isSelected: false, text: 'CO₂ emission', css: 'hidden_el', unit: 'kg', dbField: null, calculatedFieldName: 'cO2' }];
  topbuttons;
  qfirst;
  firstclick: EventEmitter<PlotAreaClickEvent>;
  qsecond;
  secondclick: EventEmitter<PlotAreaClickEvent>;
  nroflines = 0;
  firstLine = new Path({
    stroke: {
      color: "#eb008b",
      width: 2
    }
  });
  secondLine = new Path({
    stroke: {
      color: "#eb008b",
      width: 2
    }
  });
  loading: boolean;
  fastAccess: boolean = false;
  public showConfigMissingMessage: boolean = false;
  public highlightSettings: SeriesHighlight = {
    color: "#ED008C",
    opacity: 1,
    markers: { color: "#ED008C" }
  };
  changeState(ev: any) {
    var deselectedButton = this.topbuttons.find(el => el.text == this.activeView.text);
    deselectedButton.isSelected = false;
    this.activeView = this.topbuttons.find(el => ev.target.textContent == el.text);
    var selectedButton = this.activeView;
    selectedButton.isSelected = true;
    this.reloadDataForPlot(selectedButton);
  }
  onButtonClickPrev() {
    this.selectedSensors = [];
    this.selectedCycles = [];
    if (this.cyclenumber > 0) {
      this.dashboardService.loadPreviousSensorsAndCyclesForDisplay(this.cyclenumber, this.storedConfig.databaseId, this.storedConfig.machineId).subscribe((res) => {
        this.disabled = false;
        this.sensorsConfig = res['sensors'];
        this.cycles = res['cycles'];
        if (this.cycles.length > 0) {
          this.groupedCycles = groupBy(this.cycles, [{ field: "productionDate" }]);
          this.days = [...new Set(this.groupedCycles.map(item => item.value))];
        }
        this.sensorsConfig.forEach(element => {
          if (element.curveFastAccess) {
            this.selectedSensors.push(element);
          }
        });
        this.cycles.forEach(cycle => {
          this.selectedCycles.push(cycle);
          let lama = cycle['number'];
          this.cycleid = +cycle['id'];
          this.cyclenumber = lama;
        });
        this.onSelectedSensorChanged(this.selectedSensors);
        this.onSelectedCycleChanged(this.selectedCycles);
        this.onCloseCycles("");
      });
      this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
        (data) => {
          let utilityButtons = 0;
          this.cycleTimeValue = data[this.cyclenumber - 1]['cycleTime'];
          this.airConsumption = data[this.cyclenumber - 1]['airConsumption'];
          this.electricityConsumption = data[this.cyclenumber - 1]['electricityConsumption'];
          this.steamConsumption = data[this.cyclenumber - 1]['steamConsumption'];
          this.cO2 = data[this.cyclenumber - 1]['cO2'];
          this.highlightedcycleId = data[this.cyclenumber - 1]['cycleId'];
          this.highlightPoint("ev");
          this.changeStepWithCycleTime(+this.cycleTimeValue);
        }, this.storedConfig.databaseId, this.storedConfig.machineId
      );
    }
    if (this.cyclenumber == 0) { this.Prevdisabled = true; }
  }
  onTabClick() {
    var deselectedButton = this.topbuttons.find(el => el.text == this.activeView.text);
    deselectedButton.isSelected = false;
    var deselectedButtonindex = this.topbuttons.findIndex(el => el.text == this.activeView.text);
    if (deselectedButtonindex == this.topbuttons.length - 1) {
      this.activeView = this.topbuttons[0];
    } else {
      this.activeView = this.topbuttons[deselectedButtonindex + 1];
    }
    var selectedButton = this.activeView;
    selectedButton.isSelected = true;
    this.reloadDataForPlot(selectedButton);
  }
  @ViewChild("chartto", { static: false })
  public chartto: ChartComponent;
  private active = true;
  @Output() highlightedDataPoint = new EventEmitter<any>();
  public openTooltip: boolean = false;
  public info = "";
  public highlightPoint(ev) {
    if (this.highlightedcycleId != 0 && this.highlightedcycleId != undefined) {
      this.chartto.toggleHighlight(true, (p) => p.dataItem.cycleId === this.highlightedcycleId);
      this.chartto.toggleHighlight(false, (p) => p.dataItem.cycleId !== this.highlightedcycleId);
    }
  }
  formToolTipStr(reading): string {
    var resStr = reading;
    if (reading.cycleTime)
      resStr += `cycle time: ${reading.cycleTime}` + '\n';
    if (reading.airConsumption)
      resStr += `air consumption: ${reading.airConsumption.toFixed(2)}` + '\n';
    if (reading.electricityConsumption)
      resStr += `electricity consumption: ${reading.electricityConsumption.toFixed(2)}` + '\n';
    if (reading.steamConsumption)
      resStr += `steam consumption: ${reading.steamConsumption.toFixed(2)}` + '\n';
    if (reading.waterConsumption)
      resStr += `water consumption: ${reading.waterConsumption.toFixed(2)}` + '\n';
    if (reading.arproperPartemission && reading.arproperPartemission != 'Infinity')
      resStr += `CO₂ emission per part: ${reading.arproperPartemission.toFixed(2)}` + '\n';
    if (reading.cO2)
      resStr += `CO₂ emission per cycle: ${reading.cO2.toFixed(2)}` + '\n';
    if (reading.arproEmissioninkg)
      resStr += `CO₂ emission ARPRO per Cycle: ${reading.totalarproEmissioninkg.toFixed(2)}` + '\n';
    if (reading.totalEmissionPerCycle)
      resStr += `Total emissions(arpro+Cycle): ${reading.totalEmissionPerCycle.toFixed(2)}` + '\n';
    return resStr;
  }
  onButtonClicknext() {
    this.selectedSensors = [];
    this.selectedCycles = [];

    if (this.cyclenumber < this.firstcyclenumber) {
      this.dashboardService.loadNextSensorsAndCyclesForDisplay(this.cyclenumber, this.storedConfig.databaseId, this.storedConfig.machineId).subscribe((res) => {
        this.Prevdisabled = false;
        this.sensorsConfig = res['sensors'];
        this.cycles = res['cycles'];
        if (this.cycles.length > 0) {
          this.groupedCycles = groupBy(this.cycles, [{ field: "productionDate" }]);
          this.days = [...new Set(this.groupedCycles.map(item => item.value))];
        }
        this.sensorsConfig.forEach(element => {
          if (element.curveFastAccess) {
            this.selectedSensors.push(element);
          }
        });
        this.cycles.forEach(cycle => {
          this.selectedCycles.push(cycle);
          let lama = cycle['number'];
          this.cyclenumber = lama;
          this.cycleid = +cycle['id'];
        });
        this.onSelectedSensorChanged(this.selectedSensors);
        this.onSelectedCycleChanged(this.selectedCycles);
        this.onCloseCycles("");
      });
      this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
        (data) => {
          let utilityButtons = 0;
          this.cycleTimeValue = data[this.cyclenumber + 1]['cycleTime'];
          this.airConsumption = data[this.cyclenumber + 1]['airConsumption'];
          this.electricityConsumption = data[this.cyclenumber + 1]['electricityConsumption'];
          this.steamConsumption = data[this.cyclenumber + 1]['steamConsumption'];
          this.cO2 = data[this.cyclenumber + 1]['cO2'];
          this.highlightedcycleId = data[this.cyclenumber + 1]['cycleId'];
          this.highlightPoint("ev");
          this.changeStepWithCycleTime(+this.cycleTimeValue);
        }, this.storedConfig.databaseId, this.storedConfig.machineId
      );
    }
    if (this.cyclenumber == this.firstcyclenumber) { this.disabled = true; }
  }

  disabled: boolean = false;
  Prevdisabled: boolean = false;
  cyclenumber: number;
  firstcyclenumber: number;
  date : string ;

//a simple date formatting function
 dateFormat(inputDate, format) {
  //parse the input date
  const date = new Date(inputDate);

  //extract the parts of the date
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();    

  //replace the month
  format = format.replace("MM", month.toString().padStart(2,"0"));        

  //replace the year
  if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
  } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2,2));
  }

  //replace the day
  format = format.replace("dd", day.toString().padStart(2,"0"));

  return format;
}



  getSensorsAndCycles() {
 

    this.curveView = true;
    this.dashboardService.loadSensorsAndCyclesForDisplay(this.storedConfig.databaseId,this.storedConfig.machineId).subscribe((res) => {
     
      this.disabled = true;
      this.sensorsConfig = res['sensors'];
      this.cycles = res['cycles'];
      if (this.cycles.length > 0) {
        this.groupedCycles = groupBy(this.cycles, [{ field: "productiondate" }]);
        this.days = [...new Set(this.groupedCycles.map(item => item.value))];
  

      }
      this.sensorsConfig.forEach(element => {
        if (element.curveFastAccess) {
          if (this.selectedSensors.find(e => e['sensorId'] == element['sensorId'])) {

          } else {
            this.selectedSensors.push(element);

          }

        }
      });
      this.cycles.forEach(cycle => {
        this.selectedCycles.push(cycle);
        let lama = cycle['number'];
        this.cyclenumber = +lama;
        this.cycleid = +cycle['id'];
     
  
        this.firstcyclenumber = +lama;
      });
     

      this.onSelectedSensorChanged(this.selectedSensors);
      this.onSelectedCycleChanged(this.selectedCycles);
      this.onCloseCycles("");
    });
  }
  async getDataToPlot(sensorName, unit, day, startTime, endTime, cycleId, sensor, isPartofChart) {
    this.loading = true;

    if (isPartofChart) {
      this.ValuesToplot = [];
      this.seriesColor = [];
      this.valueAxes = [];
    }
    await this.dashboardService.getCurve(this.storedConfig.databaseId, day, startTime, endTime, cycleId, sensor).subscribe((res) => {
      res.forEach(element => {
        if (cycleId != 0) {
          element.cview = this.cyclesToPlot.find(x => x.id === cycleId);
          element.colorLine = this.stringToColour(sensorName);
          element.displayName = sensorName;
        }
        else {
          element.cview = this.cyclesToPlot.find(x => x.id === element.cycleId);
          let index = this.sensorsToPlot.findIndex(x => x.readingColumnName === element.sensorName);
          if (index >= 0) {
            var name = this.sensorsToPlot[index].sensorName
            element.displayName = name;
            element.colorLine = this.stringToColour(name);
          }
        }
        this.createLegend(sensorName, this.stringToColour(sensorName));
      });
      if (unit == "") {
        unit = "UNIT_NOT_CONFIGURED";
      }
      Array.prototype.push.apply(this.aggregatedValues, res);
      if (isPartofChart) {
        this.createMultipleAxis(unit);
        this.createSeriesColor(res);
        this.setLabelsOnChart(res);
        if (cycleId != 0) {
          this.ValuesToplot.push({ axis: unit, name: sensorName, data: res });
          this.loading = false;
        }
        else {
          this.cyclesToPlot.forEach(c => {
            this.sensorsToPlot.forEach(s => {
              let cycle = [];
              res.forEach(r => {
                if (c.id === r.cycleId && r.displayName === s.sensorName) {
                  cycle.push(r);
                }
              });
              this.ValuesToplot.push({ axis: unit, name: sensorName, data: cycle });
              this.loading = false;
            });
          });
        }
      }
      this.createStatsModule(sensor);
    });
  }
  onSelectedCycleChanged(ev) {
    this.cyclesToPlot = ev;
    this.cyclesToPlotChanges = true;
    if (this.selectedDays) {
      this.selectedDays = [];
    }
    if (this.cyclesToPlot.length <= 0) {
      this.trimStart = null;
      this.trimEnd = null;
      this.plot = [];
      this.cleanStats();
    }
  }
  onCloseCycles(ev) {
    if (this.sensorsToPlot != undefined && this.sensorsToPlot.length > 0 && this.cyclesToPlot.length > 0 && this.cyclesToPlotChanges) {
      this.loading = true;
      this.createIntialPlot();
    }
    this.cyclesToPlotChanges = false;
  }
  onSelectedSensorChanged(ev) {
    this.sensorsToPlot = ev;
    this.sensorsToPlotChanges = true;
    if (this.sensorsToPlot.length <= 0) {
      this.trimStart = null;
      this.trimEnd = null;
      this.plot = [];
      this.cleanStats();
    }
  }
  createIntialPlot() {
    this.plot = [];
    this.aggregatedValues = [];
    if (this.selectedDays && this.selectedDays.length > 0) {
      this.sensorsToPlot.forEach(s => {
        this.selectedDays.forEach(c => {
          var dates = c.split('/');
          let date = (dates[2] + '-' + dates[1] + '-' + dates[0]).toString();
          let length = this.selectedCycles.length;
          let startTime = this.selectedCycles[0].productionTime;
          let endTime = this.selectedCycles[length - 1].productionTime;
          this.getDataToPlot(s.sensorName, s.measurementUnit, date, startTime, endTime, 0, s.readingColumnName, true);
        });
      });
    }
    else {
      let newdate = new Date(); 
      this.date =  this.dateFormat( newdate, 'MM-dd-yyyy');
      const dates = this.date;
      this.sensorsToPlot.forEach(s => {
        this.cyclesToPlot.forEach(c => {
          let date = this.formatDate(dates);
          this.getDataToPlot(s.sensorName, s.measurementUnit, date, null, null, c.id, s.readingColumnName, true);
        });
      });
    }
    this.plot = this.ValuesToplot;
  }
  constructor(@Inject(DOCUMENT) private document: any, private el: ElementRef, private appStateService: AppStateService, private processDataLoadingService: ProcessDataLoadingService, private router: Router, private qualityDataLoadingService: QualityDataLoadingService, dataRangeManagementService: DataRangeManagementService, private dashboardService: DashboardService, public datepipe: DatePipe, public authenticationService: AuthenticationService) {
    super(dataRangeManagementService);
    this.unitsText = `[` + this.cycleTime.unit + `]`;
    let coloursSchemeService = new ColoursSchemeService();
    this.colours = coloursSchemeService.getColours();
  }
  protected setData(data, maxY, minY, maxX, minX, preserveZoom: boolean = false) {
    if (!(data == null || data.length == 0)) {
      let offset = (maxY - minY) * 0.1;
      if (offset == 0)
        offset = 0.1
      this.minY = this.roundMinMaxLimits(minY - offset, true);
      this.maxY = this.roundMinMaxLimits(maxY + offset, false);
    }
    else {
      if (data == null)
        data = [];
      this.minY = this.defaultPeriod.minY;
      this.maxY = this.defaultPeriod.maxY;
    }
    if (!preserveZoom) {
      this.minX = minX;
      this.maxX = maxX;
    }
    this.endX = new Date(maxX.setHours(maxX.getHours() + 1));
    this.startX = new Date(minX.setHours(minX.getHours() - 1));
    this.step = this.calculateStep(minX, maxX);
    this.stepY = this.calculateStepNumericAxis(this.minY, this.maxY)
    this.seriesData = data;
    this.emitRange(this.minX, this.maxX, this.minY, this.maxY, this.step);
  }
  loadQualityMetadata(endOfInterval, start, activeView) {
    this.qualityDataLoadingService.setUnitsForQData(this.storedConfig.databaseId, this.storedConfig.machineId, start, endOfInterval, (data) => {
      if (data == null) {
        data = [];
      }
      var previoslySelectedElText = undefined;
      if (this.topbuttons) {
        let previoslySelectedEl = this.topbuttons.find(e => e.isSelected == true)
        previoslySelectedElText = (previoslySelectedEl == undefined) ? undefined : previoslySelectedEl.text
      }
      this.topbuttons = this.cycleTime;
      this.topbuttons = this.topbuttons.concat(data);
      this.topbuttons.map(button => { button.isSelected = false });
      if (this.topbuttons.length > 0) {
        var elToSelect = this.topbuttons[0];
        if (previoslySelectedElText != undefined) {
          var prevButton = this.topbuttons.find(e => e.text == previoslySelectedElText);
          if (prevButton)
            elToSelect = prevButton;
        }
        elToSelect.isSelected = true;
        this.activeView = elToSelect;
      }
    });
  }
  private reloadDataForPlot(conf: any) {
  
    this.qualityDataType = conf.text;
    this.unitsText = `[${conf.unit}]`;
    let preserveZoom: boolean = false
    if (this.qualityDataType == "Cycle Time") {
      this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
        (data) => {
          let dates = new Date(data[0]['time']);
          let datestarts = new Date(data[data.length - 1]['time']);
          this.startdate = new Date(data[data.length - 1]['time']);
          this.enddate = new Date(data[0]['time']);
          let utilityButtons = 0;
          this.cycleTimeValue = data[this.cyclenumber]['cycleTime'];
          this.airConsumption = data[this.cyclenumber]['airConsumption'];
          this.electricityConsumption = data[this.cyclenumber]['electricityConsumption'];
          this.steamConsumption = data[this.cyclenumber]['steamConsumption'];
          this.cO2 = data[this.cyclenumber]['cO2'];
          this.seriesData.push(data);
          this.setNewData(data, this.activeView);
          this.reloadPlot(datestarts, dates);
          this.plotExistingTrim("ev");
          this.changeStepWithCycleTime(+this.cycleTimeValue);
        }, this.storedConfig.databaseId, this.storedConfig.machineId
      )
    } else if (this.qualityDataType != "null") {
      this.qualityDataLoadingService.setQDataPerTimeInterval(this.qualityDataType,
        this.storedConfig.databaseId,
        this.storedConfig.machineId,
        this.startdate,
        this.enddate,
        null,
        null,
        null,
        (data: QualityMeasurement[],
          minQ, maxQ, startTime, endTime) => {
          if (data != null && data.length != 0 && minQ !== Number.POSITIVE_INFINITY && maxQ !== Number.NEGATIVE_INFINITY)
            this.setData(data, maxQ, minQ, this.startdate, this.enddate, preserveZoom);
          else
            this.setData(data, null, null, this.startdate, this.enddate, preserveZoom);
        });
    }
  }
  statsType: string;
  currentSeries: string;
  private updateViewType(type: {
    isSelected: boolean, text: string, css: string, dbField: string, unit: string
    , calculatedFieldName: string
  }) {
    if (!type)
      return;
    if (!type.dbField && type.calculatedFieldName)
      this.currentSeries = type.calculatedFieldName;
    else
      this.currentSeries = type.dbField;
    this.qualityDataType = type.text;
    this.unitsText = `[${type.unit}]`;
  }
  public setNewData(seriesData, type: {
    isSelected: boolean, text: string, css: string, dbField: string, unit: string
    , calculatedFieldName: string
  }) {
    this.seriesData = seriesData;
    this.updateViewType(type);
    this.updateDataForPlot(seriesData, this.currentSeries, true);
    this.nroflines = 0;
  }
  @Output() currentPerformanceObjects = new EventEmitter<any>();
  tooltipData: any;
  private updateDataForPlot(incomingData, field: string, preserveZoom: boolean = false) {
    var programId = 0;
    var seriesName = this.currentSeries;
    this.tooltipData = new Object;
    let endOfInterval: Date = this.calculateEndOfInterval(new Date(incomingData[0]['time']));
    let start: Date = this.calculateStartOfInverval(new Date(incomingData[0]['time']), 0);
    let minY = Number.POSITIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    let arrayOfValues = [];
    if (incomingData)
      incomingData.forEach(element => {
        if (element[seriesName]) {
          minY = Math.min(minY, element[seriesName]);
          maxY = Math.max(maxY, element[seriesName]);
          arrayOfValues.push(element[seriesName]);
        }
      });
    let result = this.StandardDeviation(arrayOfValues);
    let tstdev = 3 * result.stddev;
    incomingData.map(data => {
      if (programId != null) {
        data.minY = minY - tstdev;
        data.maxY = maxY + tstdev;
        data.avg = result.avg;
      }
      else {
        data.minY = null;
        data.maxY = null;
        data.avg = null;
      }
    });
    let startTime = null;
    let endTime = null;
    if (incomingData.length != 0) {
      startTime = new Date(incomingData[0].time);
      endTime = new Date(incomingData[incomingData.length - 1].time);
    }
    if (incomingData != null && incomingData.length != 0 && minY !== Number.POSITIVE_INFINITY && maxY !== Number.NEGATIVE_INFINITY) {
      this.setData(incomingData, maxY + (3 * result.stddev), minY - (3 * result.stddev), endOfInterval, start, preserveZoom);
    }
    else
      this.setData(incomingData, null, null, endOfInterval, start, preserveZoom);
  }
  public StandardDeviation(numbersArr) {
    var total = 0;
    for (var key in numbersArr)
      total += numbersArr[key];
    var meanVal = total / numbersArr.length;
    var SDprep = 0;
    for (var key in numbersArr)
      SDprep += Math.pow((parseFloat(numbersArr[key]) - meanVal), 2);
    var SDresult = Math.sqrt(SDprep / numbersArr.length);
    return { "avg": meanVal, "stddev": SDresult };
  }
  qualityDataType: string;
  reloadPlot(date, endedate, preserveZoom: boolean = false) {
    let endOfInterval: Date = new Date(date);
    let start: Date = new Date(endedate);
    let resData = [];
    this.seriesData.forEach(element => {
      resData.push(element);
    });
    this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
      (data) => {
        let utilityButtons = 0;
        var qMeasurement: cycleTimeMeasurement[] = [];
        let selectedIds = resData.forEach(a => { qMeasurement.push({ "actualValue": a.cycleTime, "cycleId": a.cycleId, "programId": a.programId, "projectId": a.projectId == null ? 0 : a.projectId, "max": null, "min": null, "time": a.time, "target": null, "number": a.number }) });
        qMeasurement.sort(function (a, b) {
          return a.actualValue - b.actualValue
        })
        var min = qMeasurement.length > 0 ? qMeasurement[0].actualValue : 0;
        var max = qMeasurement.length > 0 ? qMeasurement[qMeasurement.length - 1].actualValue : 150;
        this.setData(qMeasurement, max, min, endOfInterval, start, preserveZoom);
      }, this.storedConfig.databaseId, this.storedConfig.machineId
    );
  }
  WithoutTime(dateTime) {
    this.dateToSHow = dateTime;
    this.datepipe.transform(this.dateToSHow, 'dd/MM/yyyy');
  }
  allowDrawing = false;
  startdate: Date;
  enddate: Date;
  dateToSHow: Date;
  cycleTimeValue: string;
  airConsumption: string;
  electricityConsumption: string;
  steamConsumption: string;
  cO2: string;
  highlightedcycleId: number = 0;
  object: any;
  cycleid: number = 0;

  // openFullscreen() {
  //   if (this.docElement.requestFullscreen) {
  //     this.docElement.requestFullscreen();
  //   } else if (this.docElement.mozRequestFullScreen) {
  //     /* Firefox */
  //     this.docElement.mozRequestFullScreen();
  //   } else if (this.docElement.webkitRequestFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     this.docElement.webkitRequestFullscreen();
  //   } else if (this.docElement.msRequestFullscreen) {
  //     /* IE/Edge */
  //     this.docElement.msRequestFullscreen();
  //   }

  //   // Supports most browsers and their versions.
  //   var requestMethod = this.docElement.requestFullScreen || this.docElement.webkitRequestFullScreen || this.docElement.mozRequestFullScreen || this.docElement.msRequestFullScreen;

  //   if (requestMethod) { // Native full screen.
  //     requestMethod.call(this.docElement);
  //   }


  //   var elem = document.body; // Make the body go full screen.



  // }

  storedConfig: any;
  ngOnInit(): void {
    this.storedConfig = JSON.parse(localStorage.getItem("CurrentConfig"));
    this.docElement = document.documentElement;
   
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };


    if (isMobile.any()) {
      var myScreenOrientation = window.screen.orientation;
      if (Math.abs(+window.orientation) === 90) {

      } else {
        myScreenOrientation.lock("landscape")
          .then(function () {
            alert('This device should be on landscape');
          })
          .catch(function (error) {
            alert(error);
          }); 

        
      }

    } else {
     
     
    }



    this.showCurrentInformation().then(this.loadfullscreen);
   
  }
  colorstring: string;
  Listpoint: any[];

  public showCurrentInformation() {

    return new Promise((resolve, reject) => {

      const chart = this.pRef;
      this.popupLegend = true;



      this.changepostionofSensors();
      this.activeView = this.buttons[0];
      this.unitsText = `[` + 's' + `]`;
      var dashboardObject = this.dashboardService.getSavedObject();
      if (dashboardObject != null) {
        this.object = dashboardObject;
        localStorage.setItem("dashBoardObject", this.object);
      } else {
        this.object = localStorage.getItem("dashBoardObject");
      }
      if (this.object != undefined) {
        if (this.object.sensors != null) {
          this.selectedSensors = this.object.sensors;
          this.selectedDays = this.object.days;
          this.selectedCycles = this.object.cycles;
          this.sensorsToPlot = this.object.sensorsToPlot;
          this.cyclesToPlot = this.object.cyclesToPlot;
          this.trimStart = this.object.trimStart;
          this.firstclick = this.object.firstclick;
          this.trimEnd = this.object.trimEnd;
          this.secondclick = this.object.secondclick;
          this.stats = this.object.stats;
          this.valueAxes = this.object.valueAxes;
          this.createIntialPlot();
        }
        else {
          this.fastAccess = true;
          this.currentConfiguration = this.object.stats;
          const date = new Date(2022, 5, 23, 15, 22, 41);
          const datestart = new Date(2022, 5, 23, 9, 52, 43);
          this.getSensorsAndCycles();
          this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
            (data) => {
              let dates = new Date(data[0]['time']);
              let datestarts = new Date(data[data.length - 1]['time']);
              this.startdate = new Date(data[data.length - 1]['time']);
              this.enddate = new Date(data[0]['time']);
              this.highlightedcycleId = data[data.length - 1]['cycleId'];
              this.WithoutTime(dates);
              let utilityButtons = 0;
              this.cycleTimeValue = data[data.length - 1]['cycleTime'];
              this.airConsumption = data[data.length - 1]['airConsumption'];
              this.electricityConsumption = data[data.length - 1]['electricityConsumption'];
              this.steamConsumption = data[data.length - 1]['steamConsumption'];
              this.cO2 = data[data.length - 1]['cO2'];
              this.seriesData.push(data);
              this.setNewData(data, this.activeView);
              this.reloadPlot(datestarts, dates);
              this.plotExistingTrim("ev");
              this.loadQualityMetadata(datestarts, dates, this.activeView);
              this.changeStepWithCycleTime(+this.cycleTimeValue);
            }, this.storedConfig.databaseId, this.storedConfig.machineId
          );
        }
      }
    });
  }
  public loadfullscreen() {
    this.docElement.requestFullscreen();
    var isMobile = {
      Android: function () {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function () {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
      },
      any: function () {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
      }
    };
    if (isMobile.any()) {

      var myScreenOrientation = window.screen.orientation;
      myScreenOrientation.lock("landscape-primary")
        .then(function () {
          alert('This device should be on landscape');
        })
        .catch(function (error) {
          alert(error);
        });
     
    }
    else {
     
    }

  }

  // public showCurrentInformation() {

  //   const chart = this.pRef;
  //   this.popupLegend = true;



  //   this.changepostionofSensors();
  //   this.activeView = this.buttons[0];
  //   this.unitsText = `[` + 's' + `]`;
  //   var dashboardObject = this.dashboardService.getSavedObject();
  //   if (dashboardObject != null) {
  //     this.object = dashboardObject;
  //     localStorage.setItem("dashBoardObject", this.object);
  //   } else {
  //     this.object = localStorage.getItem("dashBoardObject");
  //   }
  //   if (this.object != undefined) {
  //     if (this.object.sensors != null) {
  //       this.selectedSensors = this.object.sensors;
  //       this.selectedDays = this.object.days;
  //       this.selectedCycles = this.object.cycles;
  //       this.sensorsToPlot = this.object.sensorsToPlot;
  //       this.cyclesToPlot = this.object.cyclesToPlot;
  //       this.trimStart = this.object.trimStart;
  //       this.firstclick = this.object.firstclick;
  //       this.trimEnd = this.object.trimEnd;
  //       this.secondclick = this.object.secondclick;
  //       this.stats = this.object.stats;
  //       this.valueAxes = this.object.valueAxes;
  //       this.createIntialPlot();
  //     }
  //     else {
  //       this.fastAccess = true;
  //       this.currentConfiguration = this.object.stats;
  //       const date = new Date(2022, 5, 23, 15, 22, 41);
  //       const datestart = new Date(2022, 5, 23, 9, 52, 43);
  //       this.getSensorsAndCycles();
  //       this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
  //         (data) => {
  //           let dates = new Date(data[0]['time']);
  //           let datestarts = new Date(data[data.length - 1]['time']);
  //           this.startdate = new Date(data[data.length - 1]['time']);
  //           this.enddate = new Date(data[0]['time']);
  //           this.highlightedcycleId = data[data.length - 1]['cycleId'];
  //           this.WithoutTime(dates);
  //           let utilityButtons = 0;
  //           this.cycleTimeValue = data[data.length - 1]['cycleTime'];
  //           this.airConsumption = data[data.length - 1]['airConsumption'];
  //           this.electricityConsumption = data[data.length - 1]['electricityConsumption'];
  //           this.steamConsumption = data[data.length - 1]['steamConsumption'];
  //           this.cO2 = data[data.length - 1]['cO2'];
  //           this.seriesData.push(data);
  //           this.setNewData(data, this.activeView);
  //           this.reloadPlot(datestarts, dates);
  //           this.plotExistingTrim("ev");
  //           this.loadQualityMetadata(datestarts, dates, this.activeView);
  //         }
  //       );
  //     }
  //   }

  //   //this.openFullscreen();
  // }
  ngAfterViewInit(): void {
    localStorage.setItem("AutoRefreshStatus", "1");
    let timerId = setTimeout(() => {
      if (window.location.href.includes("Display")) {
        //window.location.reload();
        this.showCurrentInformation().then(this.loadfullscreen);
        // this.showCurrentInformation();
        // this.openFullscreen();

      }
    }, 300000);
    if (localStorage.getItem("AutoRefreshStatus") != null) {
      this.authenticationService.extendUserSession(true);
      // this.showCurrentInformation();
      //  this.openFullscreen();
      this.showCurrentInformation().then(this.loadfullscreen);
    }
    else {
      this.showCurrentInformation().then(this.loadfullscreen);
      // this.showCurrentInformation();
      //  this.openFullscreen();
    }
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }
  stringToColour(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
  createMultipleAxis(unit) {
    var i = this.valueAxes.findIndex(x => x.name == unit);
    if (i <= -1) {
      this.valueAxes.push({ name: unit });
    }
  }
  createLegend(name, color) {
    var i = this.sensorsToPlot.findIndex(x => x.sensorName == name)
    if (i >= 0) {
      this.sensorsToPlot[i].color = color;
    }
  }
  createSeriesColor(res) {
    this.seriesColor.push([...new Set(res.map(item => item.colorLine))]);
  }
  public labels = {
    rotation: 'auto',
    step: 5,
    font: 'bold 16px Arial'
  };
  maxTimeLabel: number = 0;
  setLabelsOnChart(res) {
    this.chartLabelTime = 0;
    this.maxTimeLabel = Math.max(...res.map(el => el.measurementTime));
    if (this.maxTimeLabel <= 250) {
      this.labels.step = 10;
    }
    else if (this.maxTimeLabel > 250 && this.maxTimeLabel <= 500) {
      this.labels.step = 20;
    }
    else if (this.maxTimeLabel > 500 && this.maxTimeLabel <= 1050) {
      this.labels.step = 45;
    }
    else {
      this.labels.step = 90;
    }
    return this.maxTimeLabel;
  }
  getFilteredArray(sensor) {
    if (this.nroflines == 2) {
      if (this.trimStart <= this.trimEnd) {
        const result = this.aggregatedValues.filter(agg => agg.sensorName == sensor && agg.measurementTime >= this.trimStart
          && agg.measurementTime <= this.trimEnd);
        return result;
      }
    }
    else {
      const result = this.aggregatedValues.filter(agg => agg.sensorName == sensor);
      return result;
    }
  }
  createStatsModule(sensor) {
    var total = 0;
    const result = this.getFilteredArray(sensor);
    result.forEach(element => {
      total += element.sensorValue;
    });
    var indexStats = this.stats.findIndex(s => s.sensor == sensor);
    if (indexStats <= -1) {
      var i = this.stats.findIndex(y => y.sensor == null);
      if (i >= 0) {
        var config = this.sensorsConfig.find(el => el.readingColumnName == sensor);
        this.stats[i].sensor = sensor;
        this.stats[i].config = config;
        this.stats[i].max = (Math.round((Math.max.apply(Math, result.map(function (o) { return o.sensorValue; })) + Number.EPSILON) * 100) / 100).toFixed(2);
        this.stats[i].min = (Math.round((Math.min.apply(Math, result.map(function (o) { return o.sensorValue; })) + Number.EPSILON) * 100) / 100).toFixed(2);
        this.stats[i].avg = (Math.round((total / result.length + Number.EPSILON) * 100) / 100).toFixed(2);
      }
    }
    else {
      this.stats[indexStats].max = (Math.round((Math.max.apply(Math, result.map(function (o) { return o.sensorValue; })) + Number.EPSILON) * 100) / 100).toFixed(2);
      this.stats[indexStats].min = (Math.round((Math.min.apply(Math, result.map(function (o) { return o.sensorValue; })) + Number.EPSILON) * 100) / 100).toFixed(2);
      this.stats[indexStats].avg = (Math.round((total / result.length + Number.EPSILON) * 100) / 100).toFixed(2);
    }
  }
  cleanStats() {
    this.stats = [
      { id: 0, sensor: null, max: "...", min: "...", avg: "...", config: {} },
      { id: 1, sensor: null, max: "...", min: "...", avg: "...", config: {} },
      { id: 2, sensor: null, max: "...", min: "...", avg: "...", config: {} },
      { id: 3, sensor: null, max: "...", min: "...", avg: "...", config: {} },
      { id: 4, sensor: null, max: "...", min: "...", avg: "...", config: {} },
      { id: 5, sensor: null, max: "...", min: "...", avg: "...", config: {} }
    ];
  }
  openPopupLegend() {
    this.popupLegend = true;
  }
  pinLegend() {
    this.popupLegend = false;
  }
  docElement: HTMLElement;
  sendTypingEvent(ev) {
    if ((ev.keyCode == 113)) {
      this.onButtonClickPrev();
    }
    else if ((ev.keyCode == 115)) {
      this.onButtonClicknext();
    }
    else if ((ev.keyCode == 9)) {
      this.onTabClick();
    }
    else if (ev.keyCode == 27) {
      this.router.navigate(['/pages/Stats'], {
        state: {
          skipLocationChange: false, data: {
            tabType: 'Stats'
          }
        }
      });
      this.closeFullscreen();
    }
  }
  /* Close fullscreen */
  closeFullscreen() {
    // if (this.document.exitFullscreen) {
    //   this.document.exitFullscreen();
    // } else
     if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
  goToCurve(ev) {
    
    var cId = ev.dataItem.cycleId;
    this.goToSelectedCycle(cId);
  }
  public showPoint(ev) {
    ev.point.color = "#ED008C";
    this.highlightPoint(ev);
    this.highlightedDataPoint.emit(ev);

  }
  goToSelectedCycle(cId: any) {


    this.selectedSensors = [];
    this.selectedCycles = [];
    if (cId > 0) {

     

      this.dashboardService.loadSelectedSensorsAndCyclesForDisplay(cId, this.storedConfig.databaseId, this.storedConfig.machineId).subscribe((res) => {
        this.disabled = false;


        this.sensorsConfig = res['sensors'];
        this.cycles = res['cycles'];


        if (this.cycles.length > 0) {
          this.groupedCycles = groupBy(this.cycles, [{ field: "productionDate" }]);
          this.days = [...new Set(this.groupedCycles.map(item => item.value))];
        }


        this.sensorsConfig.forEach(element => {
          if (element.curveFastAccess) {
            this.selectedSensors.push(element);

          }
        });



        this.cycles.forEach(cycle => {

          this.selectedCycles.push(cycle);
          let lama = cycle['number'];
          this.cycleid = cycle['id'];
          this.cyclenumber = lama;



        });
        //  this.highlightedcycleId=this.cycleid
        this.onSelectedSensorChanged(this.selectedSensors);
        this.onSelectedCycleChanged(this.selectedCycles);
        this.onCloseCycles("");

      });

    
      var cNumber = this.cycles[0].number

      this.processDataLoadingService.setCycleStatsPerTimeIntervalForDisplayView(
        (data) => {

    
          // this.getSensorsAndCycles(object.cyclesToPlot.time, 
          let utilityButtons = 0;
          this.cycleTimeValue = data[this.cyclenumber]['cycleTime'];
          this.airConsumption = data[this.cyclenumber]['airConsumption'];
          this.electricityConsumption = data[this.cyclenumber]['electricityConsumption'];
          this.steamConsumption = data[this.cyclenumber]['steamConsumption'];
          this.cO2 = data[this.cyclenumber]['cO2'];
          this.highlightedcycleId = cId;//data[this.cyclenumber]['cycleId'];

          this.highlightPoint("ev");
          this.changeStepWithCycleTime(+this.cycleTimeValue);
    
          //hide or show buttons

          

          //this.configMissingMessage=[];


        }, this.storedConfig.databaseId, this.storedConfig.machineId
      );


    }
    if (this.cyclenumber == 0) { this.Prevdisabled = true; }



  }

  plotExistingTrim(ev) {
    if (this.nroflines == 1) {
    }
    else if (this.nroflines == 2 && this.valueAxes.length > 0) {
    }
    if (!this.isZoomed) {
      this.axisMin = 0;
      this.axisMax = this.maxTimeLabel;
    }
  }
  showShiftCurvePopup: boolean = false;
  curveToShift: any;
  sensorToShift: any = "";
  shiftConfig: string = "all";
  shiftingTime: number = 1;
  args: any;
  public axisMin: number;
  public axisMax: number;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})

export class HttpClientErrorHandlingService {

  constructor(private router: Router) {


  }



  public process(err: any) {

    if (err.status == 400) {
      //bed request
      //TODO specific error message
      this.router.navigate(['login']);
      return;

    }

    if (err.status == 401) {
      this.router.navigate(['login']);
      return;
    }

  }

}
